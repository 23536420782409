import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Toaster} from "react-hot-toast";
import s from "./PatientFamilyTable.module.css";
import Plus from "../../../../assets/images/transparrent-plus.svg";

import {clearPatientId, clearPreviousPageForEditPatient} from "../../../../redux/patient/PatientSlice";
import {Empty, Table} from "antd";
import {useNavigate} from "react-router-dom";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";

const PatientsFamilyTable = ({relationshipListList, handleClick}) => {

    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const columns =  [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            sorter: (a, b) => a.patientname.length - b.patientname.length,
            sortDirections: ["ascend", "descend"],
            render: (text) => <a href="javascript:void(0)">{text}</a>,
        },
        {
            title: "DOB",
            dataIndex: "dob",
            key: "dob",
        },
        {
            title: "Relationship",
            dataIndex: "familyRelationType",
            key: "familyRelationType",
        },
        {
            title: "Can access",
            dataIndex: "canSeeRecords",
            key: "canSeeRecords",
        },
        {
            title: "Actions",
            dataIndex: "action",
            key: "action",
        },

    ];

    return (
        <div className={s.topWrapper}>
            {/* <Nav /> */}
            <Toaster position="top-center" reverseOrder={false} />
            <div  style={{width: '100%'}}>
                <div
                    style={{width: '100%'}}
                >
                    <div className={s.titleWrapper}>
                        <h3 className={s.title}>{  'Patient family relationships'}</h3>

                        {}
                    </div>
                    <div style={{float: 'right'}}>
                        <CustomButton
                            title="Add New"
                            buttonHandle={handleClick}
                            icon={Plus}
                            buttonClassName={s.addButton}
                        />
                    </div>
                </div>

                <div className="custom-table-wrapper patients-table">
                    <Table
                        dataSource={relationshipListList}
                        columns={columns}
                        locale={{
                            emptyText: isPageLoaded ? (
                                <Empty description="No Records Found"/>
                            ) : (
                                <p></p>
                            ),
                        }}
                    />
                </div>

            </div>
        </div>
    );

};

export default PatientsFamilyTable;

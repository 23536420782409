import React, { useEffect, useState } from "react";
import { createAxiosInstance } from "../../api/axiosConfig";
import {
    MESSAGE_CONTROLER_URL,
    SMS_TEMPLATE_CONTROLLER,
} from "../../api/Service";
import toast, { Toaster } from "react-hot-toast";
import $ from "jquery";
import Error from "../Error/Error";

const SmsModal2 = ({
                      tenantVal,
                      patient,
                       onClose,
                      selectedUsers,
                      user_option,
                      handleSMSModalClose,
                      selectAllChecked,
                  }) => {
    const [smsTemplates, setSmsTemplates] = useState([]);
    let [errorList, setErrorList] = useState([]);
    let [sendDisable, setSendDisable] = useState(false);


    useEffect(() => {
        getSmstemplates();
        validate();
        setFormData({
            message: "",
            groupName: "",
            smsTemplate: "",
            smsTemplateId: "",
        });
    }, [patient.id]);

    const [formData, setFormData] = useState({
        message: "",
        groupName: "",
        smsTemplate: "",
    });

    const validate = () => {
        if(!patient.mobilePhone || patient.mobilePhone.lenght < 6) {
            errorList.push('Invalid mobile number, please fix the number');
            setErrorList(errorList)
            setSendDisable(true)
        }
        if( patient.died ) {
            errorList.push('Patient is DECEASED you cannot send them a message');
            setErrorList(errorList)
            setSendDisable(true)
        }
    }

    const resetErrorList = () => {
        setErrorList([]);
    }

    const getSmstemplates = async () => {
        try {
            let t = [{ label: "SMS Templates*", value: "" }];

            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${SMS_TEMPLATE_CONTROLLER}`
            );
            if (response && response.data) {
                response.data.forEach((element) => {
                    t.push({ label: element.name, value: element.id, text: element.template });
                });
            }

            setSmsTemplates(t);
        } catch (error) {
            console.log(error);
            toast.error(error.message);
        }
    };

    const [formParam, setFormParam] = useState({
        errors: {
            email: "",
        },
        submitted: false,
    });
    const validateForm = () => {
        let formIsValid = true;
        let f = Object.assign({}, formParam);
        f.errors.message = "";
        f.errors.groupName = "";
        f.errors.smsTemplateId = "";
        if (!formData.message) {
            formIsValid = false;
            f.errors.message = "*Please enter message.";
        }
        setFormParam(f);
        return formIsValid;
    };

    const resetFormParam = () => {
        setFormParam({
            errors: {
                email: "",
            },
            submitted: false,
        });
    };

    useEffect(() => {
        validateForm();
    }, [formData.message, formData.groupName, formData.smsTemplateId]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleTemplateChange = (e) => {
        const { name, value } = e.target;

        setFormData({ ...formData, [name]: value });
    };

    const getPatientName = (patientObj) => {
        return patientObj.firstName + ' ' + patient.surname;
    }

    const handleSubmit = async (tenantVal, patientId) => {
        resetErrorList();
        formParam.submitted = true;
        if (validateForm()) {
            formParam.submitted = true;
        } else return;

        let smsT;
        if (formData.smsTemplateId)
            smsT = smsTemplates.filter((f) => f.value == formData.smsTemplateId)[0]
                .label;

        var obj = {
            tenant: tenantVal,
            message: formData.message,
            patientId: patientId,
        };

        try {
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.post(
                `${tenantVal}${MESSAGE_CONTROLER_URL}`,
                obj
            );

            setFormData({
                message: "",
                groupName: "",
                smsTemplate: "",
                smsTemplateId: "",
            });
            toast.success("Sms sent successfully.");
            onClose();
        } catch (error) {
            console.log(error);
            toast.error(error.message);
            errorList.push(error.response.data.message);
            setErrorList(errorList)
        }
    };

    return (
        <div >
            <Toaster position="top-center" reverseOrder={false} />
            <div className="dashBcontbody addpatentdashbord">

                <div className="signup_threebox addpatientlist mt-0">
                    <Error errorList={errorList}></Error>

                    <div className="modal-body">
                        <div className="bodycontdropdown">
                            <div className="row">
                                <div className="col-md-12 mb-3">
                                    <label>Recipient Name</label>
                                    <input
                                        className="form-control2"
                                        name="text"
                                        value={getPatientName(patient)}
                                        placeholder="Recipient Name"
                                        disabled
                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="requiredValidator">Mobile Number</label>
                                    <input
                                        className="form-control2"
                                        name="groupName"
                                        placeholder="Mobile Number"
                                        value={patient.mobilePhone}
                                        disabled
                                    />
                                </div>

                                <div className="col-md-12 mb-3">
                                    <label className="requiredValidator">SMS Templates</label>
                                    <select
                                        className="form-control p15"
                                        name="message"
                                        value={formData.smsTemplateId}
                                        onChange={(e) => {
                                            handleTemplateChange(e);
                                        }}
                                    >
                                        {smsTemplates.map((option) => {
                                            return (
                                                <option
                                                    key={option.value}
                                                    label={option.label}
                                                    value={option.text}
                                                >
                                                    {option.label}
                                                </option>
                                            );
                                        })}
                                    </select>
                                    {formParam.submitted && (
                                        <div className="errorMsg text-start">
                                            {formParam.errors.smsTemplateId}
                                        </div>
                                    )}
                                </div>

                            </div>
                            <div className="col-md-12 mb-3">
                                <label className="requiredValidator">Message</label>
                                <textarea
                                    className="form-control textareaform-control"
                                    rows="10"
                                    style={{minHeight: '150px'}}
                                    id="comment"
                                    name="message"
                                    placeholder="Write here..."
                                    onChange={handleChange}
                                    value={formData.message}
                                ></textarea>
                                {formParam.submitted && (
                                    <div className="errorMsg text-start">
                                        {formParam.errors.message}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div className="modal-footer">
                        <button disabled={sendDisable}
                            className="custom_btn savwidth"
                            aria-label="Close"
                            onClick={() => handleSubmit(tenantVal, patient.id)}
                            type="submit"
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SmsModal2;

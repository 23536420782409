import React, { useEffect, useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import {
    PATIENT_DOCUMENTS_GET_BY_PATIENT_ID_TYPE,
    PATIENT_CONTROLER_URL_ID, DOWNLOAD_DOCUMENT
} from "../../../../api/Service.js";
import {useDispatch, useSelector} from "react-redux";
import PatientCard from "../PatientCard/PatientCard.jsx";
import DocumentMenu from "../../Documents/DocumentMenu/DocumentMenu"
import EmptyState from "./EmptyState"

import s from "./PatientDocumentsGp.module.css";
import DocumentModal2 from "../../../../components/Modal/DocumentModal2";
import PreviewModal from '../../../../components/Modal/previewDocumentModal'; import { Empty, Table } from "antd";
import Modal from "../../../../components/helpers/Modal/Modal";
import PatientDocTable from "./PatientDocTable";
import toast from "react-hot-toast";
import {logoutFromHelper} from "../../../../api/Helper";
import {logout} from "../../../../redux/auth/AuthSlice";
import {removeuser} from "../../../../redux/user/userSlice";
import {useNavigate} from "react-router-dom";

const PatientDocumentGp = () => {
    const [patient, setPatient] = useState({});
    const [selectedItem] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    let [showUpload, setShowUpload] = useState(false);
    let [docWidget, setDocWidget] = useState(false);
    const [docData, setDocData] = useState({});
    const [menuItem, setMenuItem] = useState('OPD_LETTER');
    const [previewDocument, setPreviewDocument] = useState(null);
    const [previewImageData, setPreviewImageData] = useState(null);
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const toggleDocumentModal = () => {
        console.log("Upload doc " + showUpload)
        showUpload = !showUpload
        setShowUpload(showUpload);

    };

    console.log("Patient docs info: ", patient);

    const patientId = localStorage.getItem("kinesin-patientId");

    const tenantVal =
        useSelector((state) => state.auth.user?.tenant) ||
        localStorage.getItem("kinesin-tenant");
    console.log(`${tenantVal}${PATIENT_CONTROLER_URL_ID}1`);

    const fetchPatientById = async (id) => {
        console.log('getting patient by id ')
        console.log(patient)
        try {
            if (!id) return;
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${PATIENT_CONTROLER_URL_ID}`
            );
            if (response && response.data) {
                setPatient(response.data);
                console.log(patient)

                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (patientId) {
            fetchPatientById(patientId);
            fetchPatientDocById(patientId, 'OPD_LETTER')
        }
    }, [patientId, tenantVal]);

    useEffect(() => {
    }, [patient]);

    useEffect(() => {
        console.log('selectedItem - ' + selectedItem, '- Has changed')
        console.log(selectedItem.text, '- Has changed')
    },[selectedItem])

    const openPreview = async (id, patientId) => {
        try {
            const imageData = await downloadDocument1(id, patientId);
            setPreviewImageData(imageData);
            setPreviewDocument({ id, patientId });
            setShowPreviewModal(true);
        } catch (error) {
            console.error('Error fetching preview image:', error);
        }
    };

    const closePreview = () => {
        setPreviewDocument(null);
        setPreviewImageData(null);
        setShowPreviewModal(false);
    };

    const fetchPatientDocById = async (id, dpcType) => {
        try {
            if (!id) return;
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${PATIENT_DOCUMENTS_GET_BY_PATIENT_ID_TYPE}`.replace("{patientId}", id) + dpcType
            );
            if (response && response.data && response.data.length > 0) {

                var documentData = [];

                response.data.forEach((element) => {
                    documentData.push(
                        {
                            key: element.id,
                            datereceived: (element.created),
                            documentname: element.name ? element.name : "",
                            reviewed_by: element.reviewedBy ? element.reviewedBy.firstName : '',
                            created: element.created,
                            reviewed_date: element.reviewTime,
                            name: element.name,
                            pname: (element.patient && element.patient.firstName ? element.patient.firstName : '') + ' ' + (element.patient && element.patient.surname ? element.patient.surname : ''),
                            patientname: (
                                <>
                                    {
                                        element.patient && element.patient.firstName && <a
                                            href=""
                                            className="patient-title-wrap"
                                        >
                                            {element.patient.firstName} {element.patient.surname}
                                        </a>
                                    }
                                </>
                            ),
                            documentType: element.documentTypes ? element.documentTypes.name : '',
                            status: (
                                <>
                                    {element.status && <p className="colordark">
                                        <span className="dot1"></span>
                                        {element.status ? element.status : ""}
                                    </p>}

                                </>
                            ),
                            action: (<>
                                {element.documentUrl !== null && (
                                    <button
                                        className="view_file_btn"
                                        onClick={() => {
                                            let ID = element ? element.id : null;
                                            let patientId = element && element.patient ? element.patient.id : null;
                                            openPreview(ID, patientId);
                                        }
                                        }
                                    >
                                        View File
                                    </button>
                                )}
                            </>),
                        }


                    );
                });
                setDocData(documentData);
                docWidget = true;
                setDocWidget(docWidget);
            } else {
                docWidget = false;
                setDocWidget(docWidget);
            }
        } catch (error) {
            console.log(error);
        }
    };


    const downloadDocument = async (id, patientId) => {
        try {
            if (!id || !patientId) {
                return;
            }
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${DOWNLOAD_DOCUMENT.replace("{documentId}", id).replace(
                    "{patientId}",
                    patientId
                )}`,
                {
                    headers: {
                        "Content-Type": "application/octet-stream",
                    },
                    responseType: "blob",
                }
            );
            if (response.data instanceof Blob) {
                // Create download link and trigger download
                const downloadUrl = window.URL.createObjectURL(
                    new Blob([response.data])
                );
                const link = document.createElement("a");
                link.href = downloadUrl;
                link.setAttribute("download", `document_${id}.png`); // Assuming it's a PNG image
                document.body.appendChild(link);
                link.click();
                link.remove();
            }
        } catch (error) {
            errorHandling(error)
        }
    };
    const downloadDocument1 = async (id, patientId) => {
        try {
            if (!id || !patientId) {
                return;
            }
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${DOWNLOAD_DOCUMENT.replace('{documentId}', id).replace('{patientId}', patientId)}`,
                {
                    headers: {
                        "Content-Type": "application/octet-stream",
                    },
                    responseType: "blob"
                }
            );
            if (response.data instanceof Blob) {
                // Convert Blob to base64 string
                const reader = new FileReader();
                reader.readAsDataURL(response.data);
                return new Promise((resolve, reject) => {
                    reader.onloadend = () => {
                        resolve(reader.result);
                    };
                    reader.onerror = reject;
                });
            }
        } catch (error) {
            errorHandling(error)
        }
    };

    const errorHandling = (error) => {
        console.log(error);
        toast.error(error.message);
        if (error && error.response && error.response.status) {
            if (error.response.status == 403) {
                logoutFromHelper();
                dispatch(logout());
                dispatch(removeuser());
                navigate("/logout");
            }
        }
    };

    const menuClick = (value) => {
        setMenuItem(value)
        fetchPatientDocById(patientId, value);
    }


    let [isDocModalOpen, setIsDocModalOpen] = useState(false);
    const toggleDocModal = () => {
        console.log("Send sms")
        isDocModalOpen = !isDocModalOpen;
        setIsDocModalOpen(isDocModalOpen);
        fetchPatientDocById(patient.id, '')
    };

    return (
        <>
            <NavigationBar />
            <div style={{ margin: "0 auto" }}>
                <div className={s.wrapper}>
                    <div className={s.firstColumnWrapper}>
                        {isLoading ? (
                            <p>Patient documents is loading...</p>
                        ) : (
                            <>
                                <PatientCard patient={patient} />
                                <DocumentMenu selectedItem={selectedItem} menuClick={menuClick}></DocumentMenu>
                            </>
                        )}
                    </div>
                    <div className={s.secondColumnWrapper}>
                        <div className={s.secondColumnSubWrapper}>
                            { docWidget == true ? <PatientDocTable  handleClick={toggleDocModal}
                                                                    docType={menuItem}
                                                                    documentList={docData}
                                >
                                </PatientDocTable> :
                                <EmptyState handleClick={toggleDocumentModal}></EmptyState>
                            }

                        </div>

                    </div>

                </div>
                <PreviewModal
                    show={showPreviewModal}
                    onHide={closePreview}
                    imageData={previewImageData}
                    downloadDocument={downloadDocument}
                />


                {/* Modal */}
                {showUpload  &&
                    (
                        <Modal
                            onClose={toggleDocumentModal}
                            title={"Upload patient document"}
                            hideCancel={true}
                        >
                            {/* Modal */}
                            <DocumentModal2
                                fromPatient = {true}
                                patient={patient}
                                onClose={toggleDocumentModal}
                                docType={menuItem}
                            ></DocumentModal2>
                            {/* Modal */}
                        </Modal>)}
                {/* Modal */}

            </div>
        </>
    );
};

export default PatientDocumentGp;

import style from "./PregnancyAnteNatalConsultationContent.module.css";

export const PregnancyAnteNatalConsultationContent = ({ consultation }) => {
  return (
    <>
      <div className={style.pregnancyAnteNatalConsultationDoctorSection}>
        <h3>Doctor</h3>
        <p>{consultation.doctor}</p>
      </div>
      <div className={style.pregnancyAnteNatalConsultationExamSection}>
        <h3>Exam</h3>
        <div>
          <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
            <h4>BP</h4>
            <p>{consultation.bp}</p>
          </div>
          <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
            <h4>Urinalysis</h4>
            <p>{consultation.urinalysis}</p>
          </div>
          <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
            <h4>Ankle oedema</h4>
            <p>{consultation.ankleOedema}</p>
          </div>
          <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
            <h4>Foetal movement present as per palpation</h4>
            <p>{consultation.foetalMovement}</p>
          </div>
          <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
            <h4>Symphysis - fundal height (from 24 weeks)</h4>
            <p>{consultation.symphysisFundalHeight}</p>
          </div>
          <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
            <h4>Foetal heart heard with doppler (from 20 weeks)</h4>
            <p>{consultation.foetalHeartHeard}</p>
          </div>
        </div>
      </div>
      <div className={style.pregnancyAnteNatalBottomSection}>
        <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
          <h4>Impression</h4>
          <p>{consultation.impression}</p>
        </div>
        <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
          <h4>Plan</h4>
          <p>{consultation.plan}</p>
        </div>
        <div className={style.pregnancyAnteNatalConsultationExamSectionItem}>
          <h4>Additional Info</h4>
          <p>{consultation.additionalInfo}</p>
        </div>
      </div>
    </>
  );
};

import React, { useEffect, useState } from "react";
import CardWithTitle from "../../../../components/helpers/CardWithTitle/CardWithTitle.jsx";
import Modal from "../../../../components/helpers/Modal/Modal.jsx";
import Select from "../../../../components/helpers/Select/Select.jsx";
import Plus from "../../../../assets/images/transparrent-plus.svg";
import s from "./PatientMedicalHistory.module.css";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import { useSelector } from "react-redux";
import {
    GET_DISEASE_CODES, PATIENT_SEARCH_AUTOCOMPLETE_URL,
} from "../../../../api/Service.js";
import CustomAutocomplete from "../../../../components/helpers/CustomAutocomplete.jsx";
import Table from "../../../../components/helpers/Table/Table.jsx";
import toast, { Toaster } from "react-hot-toast";
import {PATIENT_MEDICALHISTORY, PATIENT_MEDICALHISTORY_GET_BYPATIENTID} from "../../../../api/Service";
import {Autocomplete, TextField} from "@mui/material";
import moment from "moment";

const PatientMedicalHistory = (medicalHistoryData) => {
    const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
    const [medicalDetails, setMedicalDetails] = useState("");
    const [diseaseCodeInput, setDiseaseCodeInput] = useState("");
    const [diseaseCodeData, setDiseaseCodeData] = useState([]);
    const [medicalHistory, setMedicalHistory] = useState([]);
    const [code, setCode] = useState('');
    const [codeId, setCodeId] = useState('');


    const tenantVal =
        useSelector((state) => state.auth.user?.tenant) ||
        localStorage.getItem("kinesin-tenant");
    const patientId = localStorage.getItem("kinesin-patientId");

    useEffect(() => {
        fetchPatientMedicalHistory();
        setDiseaseCodeData('')
        setFullName('')
    }, []);

    const fetchPatientMedicalHistory = async () => {
        try {
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${PATIENT_MEDICALHISTORY}${PATIENT_MEDICALHISTORY_GET_BYPATIENTID}/${patientId}`
            );
            setMedicalHistory(response.data);
        } catch (error) {
            toast.error(`Error fetching data: ${error.message}`);
            console.error("There was a problem with the axios operation:", error);
        }
    };

    const deleteMedicalHistory = async (id) => {
        try {
            const axiosInstance = createAxiosInstance();
            await axiosInstance.delete(
                `${tenantVal}${PATIENT_MEDICALHISTORY}/delete`,
                {
                    data: {
                        id: id,
                    },
                }
            );
            fetchPatientMedicalHistory();
            toast.success("Data deleted successfully!");
        } catch (error) {
            toast.error(`Error fetching data: ${error.message}`);
            console.error("There was a problem with the axios operation:", error);
        }
    };

    const handleDeleteMedicalHistory = async (id) => {
        deleteMedicalHistory(id);
    };

    const fetchDiseaseCodes = async (searchText) => {
        try {
            const axiosInstance = createAxiosInstance();
            var searchTextValue = searchText.replace(/\//g, );
            const response = await axiosInstance.get(
                `${tenantVal}${GET_DISEASE_CODES}/search/${searchTextValue}`
            );
            setDiseaseCodeData(response.data);
//            setDiseaseCodeInput(value);
//            setCode(value.code)
        } catch (error) {
            toast.error(`Error fetching data: ${error.message}`);
            console.error("There was a problem with the axios operation:", error);
        }
    };

    const handleDiseaseCodeInputChange = (event, value) => {
        console.log("check disease code gitvalue : ", value);
        console.log(value);
        fetchDiseaseCodes(value);

    };

    const handleOpenModal = (id) => {
        setIsModalOpen({ open: true, id: typeof id === "number" ? id : null });
        if (id) {
            const selectedData = medicalHistory.find((item) => item.id === id);
            if (selectedData) {
                setMedicalDetails(selectedData.medicalDetails);
                setDiseaseCodeInput(selectedData.diseaseCode);
            }
        } else {
            clearForm();
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen({ open: false, id: null });
        clearForm();
    };

    const handleSave2 = async (event) => {

        console.log("SAVING HERE FOR MODAL -------------> handleSave2");

        const formData = {
            id: isModalOpen.id ?? null,
            medicalDetails,
            diseaseCode: diseaseCodeData,
            patientId,
        };
        console.log("SAVING HERE FOR MODAL -------------> handleSave3");

        try {
            const axiosInstance = createAxiosInstance();
            console.log("SAVING HERE FOR MODAL -------------> handleSave4");

            await axiosInstance.post(
                `${tenantVal}${PATIENT_MEDICALHISTORY}`,
                formData
            );

            toast.success("Data saved successfully!");
            setIsModalOpen({ open: false, id: null });
            clearForm();
            fetchPatientMedicalHistory();
        } catch (error) {
            console.error("There was a problem with the axios operation:", error);
        }
    }


    const handleSave = async (event) => {
        event.preventDefault();

        const formData = {
            id: isModalOpen.id ?? null,
            medicalDetails,
            diseaseCode: diseaseCodeData,
            patientId,
        };

        try {
            const axiosInstance = createAxiosInstance();
            if (isModalOpen.id) {
                await axiosInstance.post(
                    `${tenantVal}${PATIENT_MEDICALHISTORY}`,
                    formData
                );
            }
            toast.success("Data saved successfully!");
            setIsModalOpen({ open: false, id: null });
            clearForm();
            fetchPatientMedicalHistory();
        } catch (error) {
            console.error("There was a problem with the axios operation:", error);
        }
    };

    const clearForm = () => {
        setMedicalDetails("");
        setDiseaseCodeInput("");
    };

    const handleMedicalDetailsChange = (event) => {
        setMedicalDetails(event.target.value);
    };

    const columns = [
        { field: "recordedDate", header: "Date Recorded" },
        { field: "diseaseCode", header: "Disease Code" },
        { field: "medicalDetails", header: "Medical Details" },
    ];

    const [fullName, setFullName] = useState('');
    const [options, setOptions] = useState([]);
    const onInputChange = (event, value, reason) => {
        if (value && value.length > 0) {
            getData(value);
        } else {
            setTimeout(() => {
                setOptions([]);
            }, 0);
        }
    };
    const getData = async (searchTerm) => {
        if (!searchTerm)
            setOptions([]);
        const axiosInstance = createAxiosInstance();

        var searchTextValue = searchTerm.replace(/\//g, );
        const response = await axiosInstance.get(
            `${tenantVal}${GET_DISEASE_CODES}` + '/search/' + searchTextValue
        );
        if (response && response.data && response.data.length > 0) {
            const updatedOptions = response.data.map((p) => {
                return {
                    id: p.code,
                    title: (p.code + ' - ' + p.name),
                };
            });
            setOptions(updatedOptions);
        } else
            setOptions([]);
    };

    return (
        <>
            <Toaster position="top-center" reverseOrder={false} />
            <CardWithTitle
                title={"Medical History"}
                width={"100%"}
                marginBottom={"34px"}
                onButtonClick={() => handleOpenModal(null)}
                buttonTitle={"Add new"}
                icon={Plus}
                className={s.addButton}
            >
                <Table
                    columns={columns}
                    data={medicalHistory}
                    tableHeader={true}
                    onDelete={handleDeleteMedicalHistory}
                    onEdit={handleOpenModal}
                />

                {isModalOpen.open && (
                    <Modal
                        title="Medical History"
                        width="560px"
                        position="center"
                        onClose={handleCloseModal}
                        onSave={handleSave2}
                    >
                        <form>

                            <div className={s.inputWrapper}>
                                <p className={s.rowTitle}>Disease Code</p>

                                <Autocomplete

                                    disablePortal
                                    id="combo-box-demo"
                                    value={fullName || null}
                                    options={options}
                                    getOptionLabel={(option) => (option && option.title ? option.title : fullName)}
                                    onInputChange={onInputChange}
                                    onChange={(event, newValue) => {
                                        setFullName(newValue ? newValue.title : '');
                                        console.log(" SELECTED VALUE + " + newValue.id)
                                        setDiseaseCodeData(newValue.id);
                                    }}
                                    renderInput={(params) => <TextField {...params} label=""
                                                                        placeholder="Search here..."/>}
                                />

                            </div>
                            <div className={s.inputWrapper}>
                                <p className={s.rowTitle}>Medical Details</p>
                                <textarea
                                    placeholder="type here"
                                    className={s.textarea}
                                    value={medicalDetails ?? ""}
                                    type="text"
                                    rows="2"
                                    maxLength="200"
                                    onChange={handleMedicalDetailsChange}
                                />
                            </div>
                        </form>
                    </Modal>
                )}
            </CardWithTitle>
        </>
    );
};

export default PatientMedicalHistory;

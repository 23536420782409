import { useState } from "react";
import dayjs from "dayjs";
import { FormProvider, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { z } from "zod";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import style from "./AddPrescriptionForm.module.css";

import Modal from "../helpers/Modal/Modal";
import { FormField } from "../form/FormField/FormField";
import CustomCheckbox from "../helpers/CustomCheckbox/CustomCheckbox";
import moment from "moment";

const formSchema = z.object({
  dosage: z.string().min(1, { message: "Please provide dosage." }),
  form: z.string().min(1, { message: "Please provide form." }),
  startDate: z.string(),
  endDate: z.string(),
  finishDay: z.boolean(),
  duration: z.string(),
  durationFormat: z.string(),
  usageType: z.string(),
  repeat: z.string(),
  paymentType: z.string().min(1, { message: "Please provide Payment type." }),
  instructions: z.string(),
});

export const AddPrescriptionForm = () => {
  const today = dayjs(new Date().setMinutes(0));
  const [isModalOpen, setIsModalOpen] = useState(false);
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      dosage: "",
      form: "",
      startDate: moment(new Date(today)).format("DD-MM-YYYY"),
      endDate: moment(new Date(today)).format("DD-MM-YYYY"),
      finishDay: false,
      duration: "",
      durationFormat: "",
      usageType: "",
      repeat: "",
      paymentType: "",
      instructions: "",
    },
  });

  const handleFormSubmit = (values) => {
    console.log(values);
    setIsModalOpen(false);
  };

  return (
    <>
      <button
        className={style.openModalButton}
        type="button"
        onClick={() => setIsModalOpen(true)}
      >
        Add Prescription
      </button>
      {isModalOpen && (
        <Modal
          title="Add Letter"
          position="center"
          onClose={() => setIsModalOpen(false)}
          hideCancel={true}
          width="800px"
        >
          <FormProvider {...form}>
            <form
              className={style.addLetterForm}
              onSubmit={form.handleSubmit(handleFormSubmit)}
            >
              <div className={style.templateSection}>
                <p className={style.addLetterFormLabel}>Templates (optional)</p>
                <div className={style.inputGroupGrid}>
                  <button className={style.openModalButton}>
                    Select template
                  </button>
                </div>
              </div>
              <div className={style.drugSection}>
                <p className={style.addLetterFormLabel}>Drug</p>
                <div className={style.inputGroupGrid}>
                  <button className={style.openModalButton}>Select drug</button>
                </div>
              </div>
              <div className={style.inputGroupGrid}>
                <FormField name="dosage">
                  <label className={style.addLetterFormLabel}>Dosage</label>
                  <input
                    className="form-control"
                    {...form.register("dosage")}
                  />
                </FormField>
                <FormField name="form">
                  <label className={style.addLetterFormLabel}>Form</label>
                  <input className="form-control" {...form.register("form")} />
                </FormField>
              </div>
              <div className={style.inputGroupGrid}>
                <FormField name="startDate">
                  <label className={style.addLetterFormLabel}>Start date</label>
                  <div className={style.addPregnancyDatePickerWrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD-MM-YYYY"
                        name="startDate"
                        defaultValue={today}
                        sx={{
                          width: "100%",
                          height: "36px",
                        }}
                        onChange={(newValue) => {
                          form.setValue(
                            "startDate",
                            moment(new Date(dayjs(newValue))).format(
                              "DD-MM-YYYY"
                            )
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                  <label className="form-check form-switch onoff_area">
                    <div className={style.switchWrapper}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        role="switch"
                        {...form.register("finishDay")}
                      />
                      <span>Finish day</span>
                    </div>
                  </label>
                </FormField>
                <FormField name="finishDate">
                  <label className={style.addLetterFormLabel}>
                    Finish date
                  </label>
                  <div className={style.addPregnancyDatePickerWrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        format="DD-MM-YYYY"
                        name="finishDate"
                        defaultValue={today}
                        sx={{
                          width: "100%",
                          height: "36px",
                        }}
                        onChange={(newValue) => {
                          form.setValue(
                            "finishDate",
                            moment(new Date(dayjs(newValue))).format(
                              "DD-MM-YYYY"
                            )
                          );
                        }}
                      />
                    </LocalizationProvider>
                  </div>
                </FormField>
              </div>
              <div className={style.inputGroupGrid}>
                <FormField name="duration">
                  <label className={style.addLetterFormLabel}>
                    Duration (optional)
                  </label>
                  <input
                    className="form-control"
                    {...form.register("duration")}
                  />
                </FormField>
                <FormField name="durationFormat">
                  <label className={style.addLetterFormLabel}>
                    Duration format (optional)
                  </label>
                  <select
                    as="select"
                    className="form-select form-control custom-select"
                    {...form.register("durationFormat")}
                  >
                    <option value="days">Days</option>
                    <option value="months">Months</option>
                  </select>
                </FormField>
              </div>
              <div className={style.inputGroupGrid}>
                <FormField name="usageType">
                  <label className={style.addLetterFormLabel}>
                    Usage type (optional)
                  </label>
                  <select
                    as="select"
                    className="form-select form-control custom-select"
                    {...form.register("usageType")}
                  >
                    <option value="long-term">Long term</option>
                    <option value="short-term">Short term</option>
                  </select>
                </FormField>
                <FormField name="repeat">
                  <label className={style.addLetterFormLabel}>
                    Repeat (optional)
                  </label>
                  <input
                    className="form-control"
                    placeholder="How many times?"
                    {...form.register("repeat")}
                  />
                </FormField>
              </div>
              <div className={style.inputGroupGrid}>
                <FormField name="payment-type">
                  <label className={style.addLetterFormLabel}>
                    Payment type
                  </label>
                  <select
                    as="select"
                    className="form-select form-control custom-select"
                    {...form.register("payment-type")}
                  >
                    <option value="cash">Cash</option>
                    <option value="card">Card</option>
                  </select>
                </FormField>
              </div>
              <FormField name="instructions">
                <label className={style.addLetterFormLabel}>
                  Instructions (optional)
                </label>
                <textarea
                  className="form-control textareaform-control"
                  style={{ minHeight: "126px" }}
                  {...form.register("instructions")}
                />
              </FormField>
              <div className={style.bottomSection}>
                <label className={style.formCheckboxLabel}>
                  <CustomCheckbox {...form.register("saveAsTemplate")} />
                  Save as template
                </label>
                <div>
                  <button
                    onClick={() => setIsModalOpen(false)}
                    className={style.openModalButton}
                    type="button"
                  >
                    Save for later
                  </button>
                  <button className={style.sendButton} type="submit">
                    Send
                  </button>
                </div>
              </div>
            </form>
          </FormProvider>
        </Modal>
      )}
    </>
  );
};

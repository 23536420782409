import { useState } from "react";
import moment from "moment";

import showMoreIcon from "../../assets/images/show-more-icon.svg";
import prescriptionIcon from "../../assets/images/pregnancy/prescription.svg";
import refferralIcon from "../../assets/images/pregnancy/refferral.svg";
import editPencil from "../../assets/images/edit-green-pencil.svg";

import style from "./ConsultationPreview.module.css";
import { ConsultationDateBox } from "./components/ConsultationDateBox/ConsultationDateBox";
import { ConsultationAttachment } from "./components/ConsultationAttachment/ConsultationAttachment";

export const ConsultationPreview = ({
  consultation,
  children,
  text,
  onEditConsultation,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div className={style.consultationWrapper}>
      <ConsultationDateBox date={consultation.date} />
      <div className={style.consultationContentWrapper}>
        <div>
          <div className={style.consultationTitleWrapper}>
            <h2>{consultation.title}</h2>
            <div>
              <p>{moment(consultation.date).format("DD/MM/YYYY, hh:mm")}</p>
              {onEditConsultation && (
                <button onClick={onEditConsultation}>
                  Edit
                  <img src={editPencil} alt="edit button" />
                </button>
              )}
            </div>
          </div>
          <p
            className={`${style.consultationText} ${
              isExpanded ? style.consultationTextExpanded : ""
            }`}
          >
            {text}
          </p>
          {(!isExpanded || !consultation.consultation) && (
            <div className={style.attachmentsInfo}>
              {consultation.attachments.refferrals.length > 0 && (
                <p>
                  <img src={refferralIcon} alt="refferral" />
                  Refferral
                </p>
              )}
              {consultation.attachments.prescriptions.length > 0 && (
                <p>
                  <img src={prescriptionIcon} alt="prescription" />
                  Prescription
                </p>
              )}
            </div>
          )}
        </div>
        {isExpanded && (
          <>
            {children}
            <div>
              {consultation.attachments.prescriptions.map((prescription) => (
                <ConsultationAttachment
                  type="prescription"
                  attachment={prescription}
                  key={prescription.id}
                />
              ))}
              {consultation.attachments.refferrals.map((refferral) => (
                <ConsultationAttachment
                  type="refferral"
                  attachment={refferral}
                  key={refferral.id}
                />
              ))}
            </div>
          </>
        )}
      </div>
      <div className={style.showMoreButtonWrapper}>
        <button
          aria-label="show more"
          onClick={() => setIsExpanded((prev) => !prev)}
          className={`${style.showMoreButton} ${
            isExpanded ? style.showMoreButtonExpanded : ""
          }`}
        >
          <img src={showMoreIcon} alt="showmore" />
        </button>
      </div>
    </div>
  );
};

import { useFormContext } from "react-hook-form";

import style from "./FormField.module.css";

export const FormField = ({ children, name }) => {
  const {
    formState: { errors },
  } = useFormContext();

  return (
    <div
      className={`${style.formField} ${
        errors[name] ? style.formFieldError : ""
      } form-field-controll`}
    >
      {children}
      {errors[name] && <p>{errors[name].message}</p>}
    </div>
  );
};

import { BiChevronDown } from "react-icons/bi";
import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        },
        inputRoot: {
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--gray-400)",
            borderRadius: "12px",
          },
          "&:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--gray-400)",
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: "var(--gray-400)",
          },
        },
      },
    },
  },
});

const CustomAutocomplete = ({
  options,
  inputValue,
  onInputChange,
  placeholder,
  required = false,
}) => {
  return (
    <ThemeProvider theme={theme}>
      <Autocomplete
        loading
        disablePortal
        id="combo-box-demo"
        options={options}
        inputValue={inputValue}
        getOptionLabel={(option) => option.code}
        sx={{
          maxWidth: "500px",
          "& .MuiInputBase-root": {
            padding: "0 20px",
            "& .MuiOutlinedInput-root": {
              paddingRight: "20px",
            },
          },
        }}
        onInputChange={(event, value) => onInputChange(event, value)}
        renderInput={(params) => (
          console.log("params", params),
          (
            <TextField
              {...params}
              required={required}
              placeholder={placeholder}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: "48px",
                  borderColor: "var(--gray-400)",
                  borderRadius: "12px",

                  "& .MuiOutlinedInput-input": {
                    // padding: "0 20px",
                  },
                  "& fieldset": {
                    borderColor: "var(--gray-400)",
                    borderRadius: "12px",
                  },
                  "&:hover fieldset": {
                    borderColor: "var(--gray-400)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "var(--gray-400)",
                  },
                },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <InputAdornment position="end">
                    <BiChevronDown /> {/* Custom icon */}
                  </InputAdornment>
                ),
              }}
            />
          )
        )}
      />
    </ThemeProvider>
  );
};

export default CustomAutocomplete;

import React, { useState, useEffect } from "react";
import styles from "../PatientCard/PatientCard.module.css";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import { useSelector } from "react-redux";
import {
    PATIENT_CONTROLER_URL_ID,
} from "../../../../api/Service.js";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";

const calculateAge = (birthDate) => {
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDifference = today.getMonth() - birth.getMonth();
    if (
        monthDifference < 0 ||
        (monthDifference === 0 && today.getDate() < birth.getDate())
    ) {
        age--;
    }
    return age;
};

const PatientDetailCard = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [patient, setPatient] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    // console.log(
    //   "patient.address.addressLine1",
    //   patient.address.addressLine1
    // );

    useEffect(() => {
        console.log("Patient Data", patient);
    }, [patient]);

    const patientId = localStorage.getItem("kinesin-patientId");

    const tenantVal =
        useSelector((state) => state.auth.user?.tenant) ||
        localStorage.getItem("kinesin-tenant");

    const fetchPatientById = async (id) => {
        try {
            if (!id) return;
            setIsLoading(true);
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
            );
            if (response && response.data) {
                setPatient(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            toast.error(`Error fetching data: ${error.message}`);
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (patientId) {
            fetchPatientById(patientId);
        }
    }, [patientId, tenantVal]);

    const handleInputAddressChange = (field, value) => {
        setPatient((prevpatient) => ({
            ...prevpatient,
            address: {
                ...prevpatient.address,
                [field]: value,
            },
        }));
    };

    const handleInputChange = (field, value) => {
        setPatient((prevpatient) => ({
            ...prevpatient,
            [field]: value,
        }));
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }
    const getInitials = (name) => {
        if (!name) return "";
        const names = name.split(" ");
        const initials = names.map((n) => n[0].toUpperCase()).join("");
        return initials;
    };

    const age = calculateAge(patient.dateOfBirth);

    const { city, addressLine1, addressLine2, postCode } = patient ? patient.address : {};

    const toggleModal = () => {
        setIsModalOpen(!isModalOpen);
    };

    return (
        <div
            className={`${styles.patientDetailCard} bg patient-detail-summary-wrap `}
        >
            <div className={styles.patientHeaderWrap}>
                <div className="d-flex align-items-center">
                    <div className={styles.patientHeaderContentWrap}>
                        <div className={styles.patientName}>
                            <p
                                title={patient.firstName}
                                className={`text-ellipsis ${styles.patientNameSurname}`}
                                style={{ fontSize: "23px" }}
                            >
                                {patient.firstName} {patient.surname} ({age})
                            </p>
                        </div>
                        <p>DOB : {moment(patient.dateOfBirth).format("DD-MM-YYYY")}
                            <span style={{color: '#ef0064', paddingLeft: '20px'}}> { patient.died ? 'DECEASED' : '' }</span>
                        </p>
                    </div>
                </div>
            </div>
            <div className={`${styles.patientBottomWrap} d-flex flex-wrap`}>
                <div className={styles.patientLeftDescription}>
                    <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} d-inline-block`}>
              Phone no:
            </span>
                        <h6 className={`${styles.h6} ${styles.textStratos} mb-0`}>
                            {patient.mobilePhone ? patient.mobilePhone : patient.homePhone}
                        </h6>
                    </div>

                    <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Address:
            </span>
                        <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0 `}>
                            {addressLine2 ? addressLine2 : addressLine1},{" "}
                            {city && `${city} ${postCode}`}
                        </h6>
                    </div>

                    <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Public:
            </span>
                        <h6
                            className={`${styles.h6} ${styles.textStratos} fw-500 mb-0 ${styles.textEllipsis}`}
                        >
                            Medical card no. {patient.medicalCardNumber}
                        </h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PatientDetailCard;

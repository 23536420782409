import prescriptionIcon from "../../../../assets/images/pregnancy/prescription.svg";
import refferralIcon from "../../../../assets/images/pregnancy/refferral.svg";
import eyeIcon from "../../../../assets/images/eye-icon.svg";

import style from "./ConsultationAttachment.module.css";

export const ConsultationAttachment = ({ type, attachment }) => {
  return (
    <div className={style.attachmentWrapper}>
      <div className={style.attachmentIcon}>
        {type === "prescription" && (
          <img alt="prescription icon" src={prescriptionIcon} />
        )}
        {type === "refferral" && (
          <img alt="refferral icon" src={refferralIcon} />
        )}
      </div>
      <div>
        {type === "prescription" && (
          <div className={style.attachmentContentWrapper}>
            <h5>Prescription</h5>
            <div className={style.prescriptionContentGrid}>
              <div>
                <h6>Drug name</h6>
                <p>{attachment.drugName}</p>
              </div>
              <div>
                <h6>Dose</h6>
                <p>{attachment.dose}</p>
              </div>
              <div>
                <h6>Type</h6>
                <p>{attachment.type}</p>
              </div>
            </div>
          </div>
        )}
        {type === "refferral" && (
          <div className={style.attachmentContentWrapper}>
            <h5>Refferral</h5>
            <div className={style.prescriptionContentGrid}>
              <div className={style.refferralContentGrid}>
                <h6>Refferral type</h6>
                <p>{attachment.type}</p>
              </div>
              <div>
                <h6>Specialist Specialization</h6>
                <p>{attachment.specialization}</p>
              </div>
            </div>
          </div>
        )}
      </div>
      <div>
        <button aria-label="see" className={style.attachmentButton}>
          <img src={eyeIcon} alt="see icon" />
        </button>
      </div>
    </div>
  );
};

import style from "./NoPregnancySection.module.css";
import noPregnancyIcon from "../../../../../assets/images/pregnancy/empty-pregnancy.svg";
import CustomButton from "../../../../../components/helpers/CustomButton/CustomButton";

export const NoPregnancySection = ({ onAddPregnancyButtonClick }) => {
  return (
    <section className={style.emptyState}>
      <img className={style.element} alt="Element" src={noPregnancyIcon} />
      <div className={style.text}>
        <div className={style.div}>
          <div className={style.textWrapper}>No Pregnancy Recorded</div>
          <p className={style.onceThePatient}>
            There is currently no recorded pregnancy for this patient.
          </p>
        </div>
        <CustomButton
          buttonClassName={style.addPregnancyButton}
          titleClassName={style.addPregnancyButtonTitle}
          buttonHandle={onAddPregnancyButtonClick}
          title={"Add Pregnancy"}
          icon={false}
        />
      </div>
    </section>
  );
};

import React, { useEffect, useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import {
    PATIENT_CONTROLER_URL_ID,
    MESSAGESENT_PATIENT_CONTROLLER_URL,
    MESSAGESENT_PATIENT_SEARCHCONTROLLER_URL,
    TASK_CONTROLER_CLOSE
} from "../../../../api/Service.js";
import { useSelector } from "react-redux";
import PatientCard from "../PatientCard/PatientCard.jsx";
import EmptyState from "./EmptyState"
import CommsMenu from "../../Communication/CommunicationMenu/CommunicationMenu"

import s from "./PatientCommunication.module.css";
import DocumentMenu from "../../Documents/DocumentMenu/DocumentMenu";
import PatientSms from "./PatientSms";
import Modal from "../../../../components/helpers/Modal/Modal";
import SmsModal2 from "../../../../components/Modal/SmsModal2";

const PatientCommunication = () => {
    const [patient, setPatient] = useState({});
    const [smsData, setSmsData] = useState({});
    let [smsWidget, setSmsWidget] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [menuItem, setMenuItem] = useState('SMS');

    console.log("Patient docs info: ", patient);

    const patientId = localStorage.getItem("kinesin-patientId");

    const tenantVal =
        useSelector((state) => state.auth.user?.tenant) ||
        localStorage.getItem("kinesin-tenant");

    const fetchPatientById = async (id) => {
        try {
            if (!id) return;
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
            );
            if (response && response.data) {
                setPatient(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    const fetchPatientSmsSearch = async (id, message) => {
        try {
            console.log("MESSAGE " + message + " " + message.length)

            if (!id) return;
            if (message.length == 0) {
                console.log("MESSAGE LENGTH " + message.length )
                return fetchPatientSmsById(id);
            }

            if (message && message.length > 3) {
                const axiosInstance = createAxiosInstance();
                const response = await axiosInstance.get(
                    `${tenantVal}${MESSAGESENT_PATIENT_SEARCHCONTROLLER_URL}`.replace("{patientId}", id) + message
                );
                if (response && response.data) {
                    setSmsData(response.data);
                    smsWidget = true;
                    setSmsWidget(smsWidget);
                }
            }
        } catch (error) {
            console.log(error);
        }
    };


    const fetchPatientSmsById = async (id) => {
        try {
            if (!id) return;
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${MESSAGESENT_PATIENT_CONTROLLER_URL}${id}`
            );
            if (response && response.data && response.data.length > 0) {
                setSmsData(response.data);
                smsWidget = true;
                setSmsWidget(smsWidget);
            } else {
                smsWidget = false;
                setSmsWidget(smsWidget);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const menuClick = (value) => {
        console.log('menu click ' + value)
        setMenuItem(value)
        if(value == 'SMS') {
            fetchPatientSmsById(patientId);
        }
    }


    let [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
    const toggleSmsModal = () => {
        console.log("Send sms")
        isSmsModalOpen = !isSmsModalOpen;
        setIsSmsModalOpen(isSmsModalOpen);
        fetchPatientSmsById(patient.id)
    };

    useEffect(() => {
        if (patientId) {
            fetchPatientById(patientId);
            fetchPatientSmsById(patientId);
        }
    }, [patientId, tenantVal]);

    useEffect(() => {
    }, [patient]);

    return (
        <>
            <NavigationBar />
            <div style={{ margin: "0 auto" }}>
                <div className={s.wrapper}>
                    <div className={s.firstColumnWrapper}>
                        {isLoading ? (
                            <p>Patient documents is loading...</p>
                        ) : (
                            <>
                                <PatientCard patient={patient} />
                                <CommsMenu menuClick={menuClick}></CommsMenu>
                            </>
                        )}
                    </div>
                    <div className={s.secondColumnWrapper}>
                        <div className={s.secondColumnSubWrapper}>
                            { smsWidget == true ? <PatientSms  handleClick={toggleSmsModal}
                                                               handleSearch={fetchPatientSmsSearch}
                                                               smsList={smsData} patient={patient}
                              >
                            </PatientSms> :
                                <EmptyState toggleSmsModal={toggleSmsModal} ></EmptyState>
                            }

                        </div>

                    </div>
                </div>
            </div>

            {/* Modal */}
            {isSmsModalOpen  &&
                (
                    <Modal
                        onClose={toggleSmsModal}
                        title={"Send sms"}
                        hideCancel={true}
                    >
                        {/* Modal */}
                        <SmsModal2
                            tenantVal={tenantVal}
                            patient={patient}
                            onClose={toggleSmsModal}
                        />
                        {/* Modal */}
                    </Modal>)}
            {/* Modal */}
        </>
    );
};

export default PatientCommunication;

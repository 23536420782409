import React from "react";

import s from "./CustomInput.module.css";

const CustomInput = ({ type, onChange, value, disabled = false }) => {
  // const handleChange = (event) => {
  //   event.persist();
  //   onChange(event.target.value);
  // };
  return (
    <>
      <input
        className={s.input}
        type={type}
        onChange={onChange}
        value={value}
        disabled={disabled}
      />
    </>
  );
};

export default CustomInput;

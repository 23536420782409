import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { menuItems } from "./utils/menuItems";
import styles from "./ReportsNavbar.module.css";
import { useNavbarRwd } from "./hooks/useNavbarRwd";

export const ReportsNavbar = () => {
  const navigate = useNavigate();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { hiddenItems, visibleItems } = useNavbarRwd(menuItems);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleMenuClick = async (e, url) => {
    navigate(url);
  };

  return (
    <div className={styles.navbar}>
      <div className={styles.navLinks}>
        {visibleItems.map((item, index) => (
          <button
            key={index}
            onClick={(e) => {
              handleMenuClick(e, item.url);
            }}
            className={
              window.location.pathname === item.url
                ? styles.navLinkChecked
                : styles.navLink
            }
          >
            <img className={styles.navIcon} src={item.src} alt={item.label} />
            <span>{item.label}</span>
          </button>
        ))}
        {hiddenItems.length > 0 && (
          <div className={styles.moreButtonWrapper}>
            <button className={styles.moreButton} onClick={toggleDropdown}>
              ⋮
            </button>
            {isDropdownOpen && (
              <div className={styles.dropdownMenu}>
                {hiddenItems.map((item, index) => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a key={index} href="#" className={styles.dropdownItem}>
                    <img
                      className={styles.navIcon}
                      src={item.src}
                      alt={item.label}
                    />
                    <span>{item.label}</span>
                  </a>
                ))}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

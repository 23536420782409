import noConsultationsIcon from "../../../../../../assets/images/pregnancy/empty-pregnancy-consultations.svg";
import CustomButton from "../../../../../../components/helpers/CustomButton/CustomButton";

import style from "./PregnancyNoAnteNatalConsultationsFound.module.css";

export const PregnancyNoAnteNatalConsultationsFound = ({
  onStartConsultationButtonClick,
}) => {
  return (
    <section className={style.emptyState}>
      <img className={style.element} alt="Element" src={noConsultationsIcon} />
      <div className={style.text}>
        <div className={style.div}>
          <div className={style.textWrapper}>
            No Ante Natal consultations yet
          </div>
          <p>
            Add the first antenatal consultation to begin monitoring the health
            of both the mother and baby.
          </p>
        </div>
        <CustomButton
          buttonClassName={style.addPregnancyButton}
          titleClassName={style.addPregnancyButtonTitle}
          buttonHandle={onStartConsultationButtonClick}
          title={"Start first consultation"}
          icon={false}
        />
      </div>
    </section>
  );
};

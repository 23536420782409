import srcicon from "../../../../../../assets/images/srcicon.svg";
import CustomCheckbox from "../../../../../../components/helpers/CustomCheckbox/CustomCheckbox";
import unselectIcon from "../../../../../../assets/images/unselect.svg";

import styles from "./ReportTable.module.css";
import { useMemo, useState } from "react";
import { SortByButton } from "./SortByButton";
import { ItemMenuButton } from "./ItemMenuButton";
import moment from "moment";
import { Loader } from "../../../../../../components/Loader/Loader";

export const ReportTable = ({ title, items, isLoading }) => {
  const [selectedItems, setSelectedItems] = useState([]);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState("newest-first");

  const filteredItems = useMemo(() => {
    const filtered = search
      ? items.filter((item) => {
          const fullName = `${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`;
          return fullName.includes(search.toLowerCase());
        })
      : items;

    return filtered.sort((a, b) => {
      const dateA = new Date(a.dateOfConsultation);
      const dateB = new Date(b.dateOfConsultation);

      if (sorting === "newest-first") {
        return dateB - dateA;
      } else {
        return dateA - dateB;
      }
    });
  }, [items, search, sorting]);

  return (
    <div className={styles.reportTableWarpper}>
      <h3>{title}</h3>
      <div className={styles.reportTable}>
        <div className={styles.reportTableTopBarWrapper}>
          <div className={`${styles.searchWrapper} search_bar`}>
            <input
              type="search"
              className="form-control"
              placeholder="Search"
              onChange={(e) => setSearch(e.target.value)}
              value={search}
            />
            <img src={srcicon} alt="" />
          </div>
          <SortByButton selectedItem={sorting} setSelectedItem={setSorting} />
        </div>
        {isLoading && <Loader>Loading reports...</Loader>}
        {filteredItems.length <= 0 && !isLoading && (
          <h4 className={styles.reportNoReportsWarning}>
            There are no reports maching current filters.
          </h4>
        )}
        {filteredItems.length > 0 && !isLoading && (
          <>
            {/* {selectedItems.length > 0 && (
              <div className={styles.reportTableSelectedItemsSection}>
                <button
                  aria-label="unselect all items"
                  onClick={() => setSelectedItems([])}
                >
                  <img src={unselectIcon} alt="unselect all items" />
                </button>
                <p>
                  {selectedItems.length} item
                  {selectedItems.length > 1 ? "s" : ""} selected
                </p>
                <button
                  onClick={() => {
                    items
                      .filter((item) => selectedItems.includes(item.id))
                      .forEach(({ downloadLink }) => {
                        window.open(downloadLink, "_blank");
                      });
                  }}
                >
                  Download
                </button>
              </div>
              )} */}
            <div className={styles.reportTableHeader}>
              <div />
              <p id="report-table-date">Consultation Date</p>
              <p id="report-table-name">Name</p>
              <p id="report-table-dob">Date of Birth</p>
              <p id="report-table-gender">Gender</p>
              <p id="report-table-doc-name">Doctor Name</p>
              <p id="report-table-prescription">Prescription</p>
              <p id="report-table-investigation">Investigation</p>
              <p id="report-table-referral">Referral</p>
              <p id="report-table-treatment">Treatment</p>
            </div>
            <ul className={styles.reportTableItems}>
              {filteredItems.map((item) => (
                <li className={styles.reportTableItem} key={item.id}>
                  {console.log(item)}
                  <div className={styles.checkboxWrapper}>
                    {/* <CustomCheckbox
                  onChange={(e) => {
                    if (e.target.checked) {
                      setSelectedItems((prev) => [...prev, item.id]);
                    } else {
                      setSelectedItems((prev) =>
                        prev.filter(
                          (prevSelecteditem) => prevSelecteditem !== item.id
                        )
                      );
                    }
                  }}
                /> */}
                  </div>
                  <p aria-describedby="report-table-date">
                    {moment(item.dateOfConsultation).format("DD/MM/YYYY")}
                  </p>
                  <p aria-describedby="report-table-name">
                    {item.firstName} {item.lastName}
                  </p>
                  <p aria-describedby="report-table-dob">
                    {moment(item.dateOfBirth).format("DD/MM/YYYY")}
                  </p>
                  <p aria-describedby="report-table-gende">{item.gender}</p>
                  <p aria-describedby="report-table-doc-name">
                    {`${item.doctorFirstName} ${item.doctorSecondName}`}
                  </p>
                  <p aria-describedby="report-table-prescription">
                    {item.prescription || "-"}
                  </p>
                  <p aria-describedby="report-table-investigation">
                    {item.investigation || "-"}
                  </p>
                  <p aria-describedby="report-table-referral">
                    {item.referral || "-"}
                  </p>
                  <p aria-describedby="report-table-treatments">
                    {item.treatments || "-"}
                  </p>
                  {/* <ItemMenuButton downloadUrl={item.downloadLink} /> */}
                </li>
              ))}
            </ul>
          </>
        )}
      </div>
    </div>
  );
};

import styles from './DocumentMenu.module.css';

import React, {useEffect, useState} from "react";
import OPDletters from "../../../../assets/images/OPD-letters.svg";
import Letters from "../../../../assets/images/Letters.svg";
import Forms from "../../../../assets/images/Forms.svg";
import EReferrals from "../../../../assets/images/E-referrals.svg";
import OtherRefferals from "../../../../assets/images/Other-refferrals.svg";
import OtherDocs from "../../../../assets/images/Other-documents.svg";

const Items = ({menuClick}) => {

    const [activeIndex, setActiveIndex] = useState(-1)
    let [selectedItem, setSelectedItem] = useState({});


    const handleMenuClick = async (e, menuItem, index) => {
        setSelectedItem(menuItem);
        e.currentTarget.classList.toggle(styles.menuItemChecked);
        setActiveIndex(index)
        console.log(selectedItem.text);
        selectedItem = selectedItem;
        menuClick(menuItem.filter.toUpperCase())
    };

    useEffect(() => {
    },[selectedItem])

    const menuLinks = [
        {text : 'OPD Letters', filter: 'OPD_LETTER', icon: `${OPDletters}`},
        {text : 'Letters', filter: 'LETTER', icon: `${Letters}`},
        {text : 'Forms', filter: 'FORMS', icon: `${Forms}`},
        {text : 'E-Referrals', filter: 'E_REFERRAL', icon: `${EReferrals}`},
        {text : 'Other Refferals', filter: 'OTHER_REFERRAL', icon: `${OtherRefferals}`},
        {text : 'Other Document', filter: 'OTHER', icon: `${OtherDocs}`}
    ];

    const isChecked = (name) => {
        console.log(selectedItem)
        if(Object.keys(selectedItem).length == 0  && name == 'OPD Letters') {
            return styles.menuItemChecked;
        } else {
            if(selectedItem.text == name) {
                return styles.menuItemChecked;
            }
        }
        return styles.menuItem;
    }

    return (
        <div className={`${styles.documentMenu} bg`}>
            {menuLinks.map( (item, index) => (
                <div className={`${styles.menuItem} ${(index === activeIndex || (index == 0 && activeIndex ==-1)) ? styles.menuItemChecked :""}`} onClick={(e) => {
                    handleMenuClick(e, item, index);
                }}>
                    <img className={styles.menuIcon} alt="" src={item.icon} />
                    <b className={styles.menuText}>{item.text}</b>
                </div>
            ))}
        </div>)
}
export default Items;
export const reportTypeToTableTitle = (reportType) => {
  if (reportType === "immunisations") {
    return "Immunisations";
  }
  if (reportType === "prescriptions") {
    return "Prescriptions";
  }
  if (reportType === "investigations") {
    return "Investigations";
  }
  if (reportType === "letters") {
    return "Letters";
  }
  if (reportType === "accounting") {
    return "Accounting";
  }
  if (reportType === "appointments") {
    return "Appointments";
  }
  if (reportType === "medico-legal") {
    return "Medico - Legal";
  }
  if (reportType === "audit-log") {
    return "Audit Log";
  }

  return "Patients";
};

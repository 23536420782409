import styles from './PaymentMenu.module.css';

import React, {useEffect, useState} from "react";
import History from "../../../../assets/images/History.svg";
import FamilyHistory from "../../../../assets/images/apple-wallet.svg";
import Card from "../../../../assets/images/mastercard-card.svg";

const Items = () => {

    const [activeIndex, setActiveIndex] = useState(-1)
    let [selectedItem, setSelectedItem] = useState({});


    const handleMenuClick = async (e, menuItem, index) => {
        setSelectedItem(menuItem);
        e.currentTarget.classList.toggle(styles.menuItemChecked);
        setActiveIndex(index)
        console.log(selectedItem.text);
        selectedItem = selectedItem;
    };

    useEffect(() => {
        console.log('child selectedItem - ' + selectedItem, '- Has changed')
        console.log('child ' + selectedItem.text, '- Has changed')
    },[selectedItem])

    const menuLinks = [
        {text : 'Payment history', filter: 'SMS', icon: `${History}`},
        {text : 'Family payment history', filter: 'EMAIL', icon: `${FamilyHistory}`},
        {text : 'Card details', filter: 'LETTER', icon: `${Card}`}
    ];

    const isChecked = (name) => {
        console.log(selectedItem)
        if(Object.keys(selectedItem).length == 0  && name == 'OPD Letters') {
            return styles.menuItemChecked;
        } else {
            if(selectedItem.text == name) {
                return styles.menuItemChecked;
            }
        }
        return styles.menuItem;
    }

    return (
        <div className={`${styles.paymentMenu} bg`}>
            {menuLinks.map( (item, index) => (
                <div className={`${styles.menuItem} ${(index === activeIndex || (index == 0 && activeIndex ==-1)) ? styles.menuItemChecked :""}`} onClick={(e) => {
                    handleMenuClick(e, item, index);
                }}>
                    <img className={styles.menuIcon} alt="" src={item.icon} />
                    <b className={styles.menuText}>{item.text}</b>
                </div>
            ))}
        </div>)
}
export default Items;
import { z } from "zod";

export const formSchema = z.object({
  issues: z.string().min(1, { message: "Enter the information about Issues." }),
  postNatalMood: z
    .string()
    .min(1, { message: "Enter the information about Post Natal Mood." }),
  impression: z
    .string()
    .min(1, { message: "Enter the information about Impression." }),
  plan: z.string().min(1, { message: "Enter the information about Plans." }),
  supports: z
    .string()
    .min(1, { message: "Enter the information about Supports." }),
  mirena: z.string().min(1, { message: "Enter the information about Mirena." }),
  contraception: z
    .string()
    .min(1, { message: "Enter the information about Contraception." }),
  gravida: z.string().min(1, { message: "Enter the number of pregnancies." }),
  para: z.string().min(1, { message: "Enter the number of births." }),
  weight: z.string().min(1, { message: "Enter the information about Weight." }),
  length: z.string().min(1, { message: "Enter the information about Length." }),
  headCircumference: z
    .string()
    .min(1, { message: "Enter the information about Head Circumference." }),
  crt: z.enum(["yes", "no"]),
  bornAt: z
    .string()
    .min(1, { message: "Enter the information about Born Date." }),
  weightAtBirth: z
    .string()
    .min(1, { message: "Enter the information about Weight At Birth." }),
  deliveryMethod: z.enum([
    "c-section",
    "vaginal-delivery",
    "assisted-vaginal-delivery.",
  ]),
  phnVisit: z
    .string()
    .min(1, { message: "Enter the information about PHN Visit." }),
  issuesOfNote: z
    .string()
    .min(1, { message: "Enter the information about issues of note." }),
  jaudice: z.boolean(),
  feeding: z.enum(["Breast", "Formula", "Breast and Formula combination"]),
  umbilicus: z
    .string()
    .min(1, { message: "Enter the information about Umbilicus." }),
  barlowAndOrlaniTest: z.string().min(1, {
    message: "Enter the information about Barlow and Orlani's Test.",
  }),
  heart: z.string().min(1, { message: "Enter the information about Heart." }),
  fonantelles: z
    .string()
    .min(1, { message: "Enter the information about Fonantelles." }),
  buccalMucosa: z
    .string()
    .min(1, { message: "Enter the information about Buccal Mucosa." }),
  feet: z.string().min(1, { message: "Enter the information about Feet." }),
});

import { useState } from "react";

export const views = {
  noPregnancies: "noPregnancies",
  general: "general",
  anteNatal: "anteNatal",
  postNatal: "postNatal",
};

export const useView = (pregnancies) => {
  const [view, setView] = useState(
    pregnancies?.length <= 0
      ? {
          name: views.noPregnancies,
          pregnancyId: null,
        }
      : {
          name: views.general,
          pregnancyId: pregnancies?.at(0)?.id,
        }
  );

  return [view, setView];
};

import { useState } from "react";

import srcicon from "../../../../../assets/images/srcicon.svg";

import styles from "./VaccinesTable.module.css";
import { SortByButton } from "./SortByButton";
import { VaccinesTableItem } from "./VaccinesTableItem";
import { Toaster } from "react-hot-toast";
import { useQuery } from "@tanstack/react-query";
import { fetchImmunizations } from "../utils/fetchImmunizations";

const items = [
  {
    id: "1",
    typeOfVaccine: "6 iin 1",
    dateGiven: "05/06/2024",
    type: "Influenza",
    batchNo: "445542",
    expiryDate: "05/06/2025",
    manufacturer: "Pfizer",
    immunisationType: "type of immunistaion",
    dose: "20ml",
    administrationalMethod: "Intramuscular (IM)",
    siteGiven: "Left Deltoid",
  },
  {
    id: "2",
    typeOfVaccine: "6 iin 1",
    dateGiven: "05/06/2024",
    type: "Influenza",
    batchNo: "445542",
    expiryDate: "05/06/2025",
    manufacturer: "Pfizer",
    immunisationType: "type of immunistaion",
    dose: "20ml",
    administrationalMethod: "Intramuscular (IM)",
    siteGiven: "Left Deltoid",
  },
  {
    id: "3",
    typeOfVaccine: "6 iin 1",
    dateGiven: "05/06/2024",
    type: "Influenza",
    batchNo: "445542",
    expiryDate: "05/06/2025",
    manufacturer: "Pfizer",
    immunisationType: "type of immunistaion",
    dose: "20ml",
    administrationalMethod: "Intramuscular (IM)",
    siteGiven: "Left Deltoid",
  },

  {
    id: "4",
    typeOfVaccine: "6 iin 1",
    dateGiven: "05/06/2024",
    type: "Influenza",
    batchNo: "445542",
    expiryDate: "05/06/2025",
    manufacturer: "Pfizer",
    immunisationType: "type of immunistaion",
    dose: "20ml",
    administrationalMethod: "Intramuscular (IM)",
    siteGiven: "Left Deltoid",
  },
  {
    id: "5",
    typeOfVaccine: "6 iin 1",
    dateGiven: "05/06/2024",
    type: "Influenza",
    batchNo: "445542",
    expiryDate: "05/06/2025",
    manufacturer: "Pfizer",
    immunisationType: "type of immunistaion",
    dose: "20ml",
    administrationalMethod: "Intramuscular (IM)",
    siteGiven: "Left Deltoid",
  },
  {
    id: "6",
    typeOfVaccine: "6 iin 1",
    dateGiven: "05/06/2024",
    type: "Influenza",
    batchNo: "445542",
    expiryDate: "05/06/2025",
    manufacturer: "Pfizer",
    immunisationType: "type of immunistaion",
    dose: "20ml",
    administrationalMethod: "Intramuscular (IM)",
    siteGiven: "Left Deltoid",
  },
  {
    id: "7",
    typeOfVaccine: "6 iin 1",
    dateGiven: "05/06/2024",
    type: "Influenza",
    batchNo: "445542",
    expiryDate: "05/06/2025",
    manufacturer: "Pfizer",
    immunisationType: "type of immunistaion",
    dose: "20ml",
    administrationalMethod: "Intramuscular (IM)",
    siteGiven: "Left Deltoid",
  },
];

export const VaccinesTable = ({ patientId, tenantVal, seletedVaccineType }) => {
  const [sorting, setSorting] = useState("newest-first");

  const { isLoading, data } = useQuery({
    queryKey: ["vaccines", tenantVal, seletedVaccineType],
    queryFn: () => fetchImmunizations(tenantVal, seletedVaccineType),
  });

  console.log("immunizations", data);

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={styles.tableWarpper}>
        <div className={styles.table}>
          <div className={styles.tableTopBarWrapper}>
            <div className={`${styles.searchWrapper} search_bar`}>
              <input
                type="search"
                className="form-control"
                placeholder="Search"
              />
              <img src={srcicon} alt="" />
            </div>
            <SortByButton selectedItem={sorting} setSelectedItem={setSorting} />
          </div>
          <div className={styles.tableHeader}>
            <p id="report-table-type-of-vaccine">Type of Vaccine</p>
            <p id="report-table-date-given">Date Given</p>
            <p id="report-table-type">Type</p>
            <p id="report-table-batch-no">Batch No.</p>
            <p id="report-table-expiry-date">Expiry Date</p>
          </div>
          <ul className={styles.tableItems}>
            {items.map((item) => (
              <VaccinesTableItem
                key={item.id}
                item={item}
                patientId={patientId}
                tenantVal={tenantVal}
                isMultiDose={seletedVaccineType === "multi-dose"}
              />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
};

import React, { useState, useEffect } from "react";
import TransparentButton from "../../../../../../components/helpers/TransparentButton/TransparentButton.jsx";
import Table from "../../../../../../components/helpers/Table/Table.jsx";
import Modal from "../../../../../../components/helpers/Modal/Modal.jsx";
import Select from "../../../../../../components/helpers/Select/Select.jsx";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { useSelector } from "react-redux";

import s from "./Intolerance.module.css";
import { createAxiosInstance } from "../../../../../../api/axiosConfig.js";
import CustomCheckbox from "../../../../../../components/helpers/CustomCheckbox/CustomCheckbox.jsx";
import CustomDateInput from "../../../../../../components/helpers/CustomDateInput/CustomDateInput.jsx";
import CustomButton from "../../../../../../components/helpers/CustomButton/CustomButton.jsx";
import Plus from "../../../../../../assets/images/transparrent-plus.svg";
import CustomInput from "../../../../../../components/helpers/CustomInput/CustomInput.jsx";
import toast, { Toaster } from "react-hot-toast";

const Intolerances = () => {
  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  const [name, setName] = useState("");
  const [allergyReaction, setAllergyReaction] = useState("RASH");
  const [serious, setSerious] = useState(false);

  const [data, setData] = useState([]);

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const userName =
    useSelector((state) => state.auth.user?.name) ||
    localStorage.getItem("kinesin-name");

  useEffect(() => {
    fetchData();
  }, []);

  const patientId = localStorage.getItem("kinesin-patientId");

  const fetchData = async () => {
    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(`${tenantVal}/allergy/getbyallergytype/INTOLERANCE/bypatient/${patientId}`);
      const dataWithUser = response.data.map((item) => ({
        ...item,
        entry: userName,
      }));
      setData(
        dataWithUser.filter((item) => item.allergyType === "INTOLERANCE")
      );
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const deleteData = async (id) => {
    try {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.delete(`${tenantVal}/allergy/delete`, {
        data: {
          id,
        },
      });
      fetchData();
    } catch (error) {
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const handleOpenModal = (id) => {
    console.log("id : ", id);
    setIsModalOpen({ open: true, id: typeof id === "number" ? id : null });
    if (id) {
      const selectedData = data.find((item) => item.id === id);
      if (selectedData) {
        setName(selectedData.name);
        setAllergyReaction(selectedData.allergyReaction);
        setSerious(selectedData.serious);
      }
    }
  };
  const handleCloseModal = () => {
    setIsModalOpen({ open: false, id: null });
    clearForm();
  };

  const handleSave = async (event) => {
    event.preventDefault();
    const formData = {
      id: isModalOpen.id ?? null,
      name,
      allergyType: "INTOLERANCE",
      allergyReaction,
      serious: false,
      patientId: patientId
    };

    try {
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.post(
        `${tenantVal}/allergy`,
        formData
      );
      toast.success("Data saved successfully!");
      setIsModalOpen({ open: false, id: null });
      clearForm();
      fetchData();
    } catch (error) {
      toast.error(`Error saving data: ${error.message}`);
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const clearForm = () => {
    setName("");
    setAllergyReaction("");
    setSerious(false);
  };

  const columns = [
    { header: "Name", field: "name" },
    { header: "Symptoms", field: "allergyReaction" },
  ];

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div className={s.topWrapper}>
        <h3 className={s.title}>Intolerance</h3>
        <CustomButton
          title="Add new"
          buttonHandle={handleOpenModal}
          icon={Plus}
          buttonClassName={s.addBtn}
        />
      </div>
      <Table
        columns={columns}
        data={data}
        tableHeader={true}
        onDelete={deleteData}
        onEdit={handleOpenModal}
        onSave={handleSave}
      />

      {isModalOpen.open && (
        <Modal
          width="560px"
          title="Intolerance"
          onClose={handleCloseModal}
          onSave={handleSave}
          position="center"
        >
          <form onSubmit={handleSave}>
            <div className={s.inputWrapper}>
              <label>Name</label>
              <CustomInput
                type="text"
                value={name ?? ""}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className={s.inputWrapper}>
              <label>Allergy Type</label>
              <CustomInput type="text" value="INTOLERANCE" disabled={true} />
            </div>
            <div className={s.inputWrapper}>
              <label>Symptoms</label>
              {/* <CustomInput type="text" /> */}
              <Select
                options={["RASH", "NAUSEA", "ANAPHYLAXIS"]}
                value={allergyReaction ?? ""}
                onChange={(value) => setAllergyReaction(value)}
                required
              />
            </div>

          </form>
        </Modal>
      )}
    </>
  );
};

export default Intolerances;

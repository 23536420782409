import { useQuery } from "@tanstack/react-query";
import { useState } from "react";

import style from "./PregnancyAnteNatalView.module.css";

import { ConsultationPreview } from "../../../../../components/ConsultationPreview/ConsultationPreview";
import { PregnancyAnteNatalConsultationContent } from "./PregnancyAnteNatalConsultation/PregnancyAnteNatalConsultationContent";
import { PregnancyNoAnteNatalConsultationsFound } from "./PregnancyNoAnteNatalConsultationsFound/PregnancyNoAnteNatalConsultationsFound";
import { fetchAnteNatalConsultations } from "../utils/fetchAnteNatalConsultations";
import { PregnancyAddAnteNatalConsultationForm } from "./PregnancyAddAnteNatalConsultationForm/PregnancyAddAnteNatalConsultationForm";
import { PregnancyLoader } from "../components/PregnancyLoader/PregnancyLoader";
import { PregnancyEditAnteNatalConsultationForm } from "./PregnancyEditAnteNatalConsultationForm/PregnancyEditAnteNatalConsultationForm";

export const PregnancyAnteNatalView = ({ pregnancy, tenantVal, patientId }) => {
  const [isEditView, setIsEditView] = useState({ open: false, id: null });
  const [isInConsultationMode, setIsInConsultationMode] = useState(false);
  const { isLoading, data: anteNatalConsultations } = useQuery({
    queryKey: ["anteNatalConsultations", pregnancy.id, tenantVal],
    queryFn: () => fetchAnteNatalConsultations(pregnancy.id, tenantVal),
  });

  if (isLoading) {
    return (
      <PregnancyLoader>Loading ante natal consultations...</PregnancyLoader>
    );
  }

  if (isEditView.open) {
    return (
      <div
        className={`${style.pregnancyAnteNatalView} ${style.pregnancyAnteNatalViewConsultationMode}`}
      >
        <div className={style.pregnancyAnteNatalViewTitleWrapper}>
          <h1 className={style.pregnancyAnteNatalViewTitle}>
            Edit Natal consultation
          </h1>
        </div>
        <PregnancyEditAnteNatalConsultationForm
          closeView={() => {
            setIsEditView({ open: false, id: null });
          }}
          tenantVal={tenantVal}
          pregnancyId={pregnancy.id}
          pregnancyHistory={pregnancy.history}
          patientId={patientId}
          anteNatalConsultation={anteNatalConsultations.find(
            (consultation) => consultation.id === isEditView.id
          )}
        />
      </div>
    );
  }

  if (anteNatalConsultations?.length < 1) {
    return (
      <>
        {isInConsultationMode ? (
          <PregnancyAddAnteNatalConsultationForm
            closeView={() => setIsInConsultationMode(false)}
            tenantVal={tenantVal}
            pregnancyId={pregnancy.id}
            pregnancyHistory={pregnancy.history}
            patientId={patientId}
          />
        ) : (
          <PregnancyNoAnteNatalConsultationsFound
            onStartConsultationButtonClick={() => setIsInConsultationMode(true)}
          />
        )}
      </>
    );
  }

  return (
    <div
      className={`${style.pregnancyAnteNatalView} ${
        isInConsultationMode ? style.pregnancyAnteNatalViewConsultationMode : ""
      }`}
    >
      <div className={style.pregnancyAnteNatalViewTitleWrapper}>
        <h1 className={style.pregnancyAnteNatalViewTitle}>
          Ante Natal consultations
        </h1>
        {!isInConsultationMode && (
          <button
            onClick={() => setIsInConsultationMode(true)}
            className={style.pregnancyAnteNatalStartConsultationButton}
          >
            Start consultation
          </button>
        )}
      </div>
      {isInConsultationMode ? (
        <PregnancyAddAnteNatalConsultationForm
          closeView={() => setIsInConsultationMode(false)}
          tenantVal={tenantVal}
          pregnancyId={pregnancy.id}
          pregnancyHistory={pregnancy.history}
          patientId={patientId}
        />
      ) : (
        <div className={style.pregnancyAnteNatalStartConsultationsWarpper}>
          {anteNatalConsultations.map((consultation) => (
            <ConsultationPreview
              key={consultation.id}
              consultation={consultation}
              text={pregnancy.history}
              onEditConsultation={() =>
                setIsEditView({ open: true, id: consultation.id })
              }
            >
              <PregnancyAnteNatalConsultationContent
                consultation={consultation}
              />
            </ConsultationPreview>
          ))}
        </div>
      )}
    </div>
  );
};

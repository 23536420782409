import React, { useEffect, useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import { PATIENT_CONTROLER_URL_ID } from "../../../../api/Service.js";
import { useSelector } from "react-redux";
import PatientCard from "../PatientCard/PatientCard.jsx";

import s from "./PatientProtocols.module.css";
import ComingSoon from "../ComingSoon";

const PatientProtocols = () => {
    const [patient, setPatient] = useState({});
    const [isLoading, setIsLoading] = useState(true);

    console.log("Patient docs info: ", patient);

    const patientId = localStorage.getItem("kinesin-patientId");

    const tenantVal =
        useSelector((state) => state.auth.user?.tenant) ||
        localStorage.getItem("kinesin-tenant");
    console.log(`${tenantVal}${PATIENT_CONTROLER_URL_ID}1`);

    const fetchPatientById = async (id) => {
        try {
            if (!id) return;
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(
                `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
            );
            if (response && response.data) {
                setPatient(response.data);
                setIsLoading(false);
            }
        } catch (error) {
            console.log(error);
            setIsLoading(false);
        }
    };

    useEffect(() => {
        if (patientId) {
            fetchPatientById(patientId);
        }
    }, [patientId, tenantVal]);

    useEffect(() => {
        console.log("Оновлені дані пацієнта:", patient);
    }, [patient]);

    return (
        <>
            <NavigationBar />
            <div style={{ margin: "0 auto" }}>
                <div className={s.wrapper}>
                    <div className={s.firstColumnWrapper}>
                        {isLoading ? (
                            <p>Patient documents is loading...</p>
                        ) : (
                            <>
                                <PatientCard patient={patient} />
                            </>
                        )}
                    </div>
                    <div className={s.secondColumnWrapper}>
                        <div className={s.secondColumnSubWrapper}>
                            <ComingSoon></ComingSoon>
                        </div>
                        <div className={s.thirdColumnWrapper}>



                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default PatientProtocols;

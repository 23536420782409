import React, {useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Toaster} from "react-hot-toast";
import s from "./PatientsTable.module.css";
import Plus from "../../assets/images/transparrent-plus.svg";

import {clearPatientId, clearPreviousPageForEditPatient} from "../../redux/patient/PatientSlice";
import {Empty, Table} from "antd";
    import {useNavigate} from "react-router-dom";
import CustomButton from "../helpers/CustomButton/CustomButton";

const PatientsTable = ({patientList, title}) => {

    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const onButtonClick = (event) => {

    }

    const columns =  [
        {
            title: "Name",
            dataIndex: "patientname",
            key: "patientname",
            sorter: (a, b) => a.patientname.length - b.patientname.length,
            sortDirections: ["ascend", "descend"],
            render: (text) => <a href="javascript:void(0)">{text}</a>,
        },
        {
            title: "DOB",
            dataIndex: "dob",
            key: "dob",
        },
        {
            title: "Address Line 1",
            dataIndex: "addressline",
            key: "addressline",
        },
        {
            title: "Telephone",
            dataIndex: "telephone",
            key: "telephone",
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
        },

    ];

    return (
        <div>
            {/* <Nav /> */}
            <Toaster position="top-center" reverseOrder={false} />
            <div className="">
                <div
                    className={s.topWrapper}
                >
                    <div className={s.titleWrapper}>
                        <h3 className={s.title}>{ title !== undefined ? title : 'Patients found'}</h3>

                        {}
                    </div>
                    <CustomButton
                        title="Add New"
                        buttonHandle={onButtonClick}
                        icon={Plus}
                        buttonClassName={s.addButton}
                    />
                </div>

                <div className="custom-table-wrapper patients-table">
                    <Table
                        dataSource={patientList}
                        columns={columns}
                        locale={{
                            emptyText: isPageLoaded ? (
                                <Empty description="No Records Found"/>
                            ) : (
                                <p></p>
                            ),
                        }}
                    />
                </div>

            </div>
        </div>
    );

};

export default PatientsTable;

import styles from "./ConsultationCard.module.css";
import {createAxiosInstance} from "../../../../api/axiosConfig";
import {PATIENT_CONTROLER_URL_ID} from "../../../../api/Service";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const ConsultationCard = (patient) => {

    const navigate = useNavigate();

    const showNextConsultation =  () => {
        console.log(patient.patient.nextApppointment + " next appointment");
        console.log(patient)
        if(patient && patient.patient.nextApppointment)
            return true;
        return false;
    };

    const newAppointmentClick = () => {
        navigate("/appointments");
    }

    return (
        <div className={styles.nnumbersParent}>
            <div className={styles.nnumbers}>
                <div className={styles.upcomingConsultation}>Upcoming consultation</div>
                <div className={styles.march2023Parent}>
                    {showNextConsultation() == true ?
                        <b className={styles.b}>{ patient.patient.nextApppointment }</b>
                        : <b className={styles.b}>None scheduled</b>}

                    <b className={styles.b}></b>

                </div>
            </div>
            {showNextConsultation() == true ?
                <div className={styles.button}>
                    <div className={styles.bookAnAppointment}>Start consultation</div>
                </div>
                :
                <div className={styles.button}>
                    <div className={styles.bookAnAppointment} onClick={newAppointmentClick}>Book an appointment</div>
                </div>
            }
        </div>);
}

export default ConsultationCard;
import { createAxiosInstance } from "../../../../../api/axiosConfig.js";

export const fetchImmunizations = async (tenantVal, immunisationType) => {
  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(
    `${tenantVal}/immunisation/getbytype/${immunisationType}`
  );

  return response.data;
};

import React from "react";
import badicon from "../../assets/images/badicon.svg";
import style from "./Error.module.css"

const Error = ({errorList}) => {

    const displayErrors = () => {
        if(errorList) {
            errorList = errorList.filter(item => (typeof item === "object" || typeof item === "string" ))
        }

        if(errorList && errorList.length > 0) {
            console.log('RETURNING TRUE')

            return true;
        }
        return false;
    }

    const checkErrorText = (text) => {
        if(text && text.length > 0) {
            return true;
        }
        return false;
    }

    return (
        <>
            {displayErrors() ?
            <div className="">
                <div className={style.badiconbox}>
                    <div className="row">
                        <div className="col-md-12">
                            <img src={badicon}  className={style.errorIcon} alt=""/>
                            <h6 className={style.badiconboxH6} >An error has occurred</h6>
                        </div>
                    </div>
                    <div className="row">
                        <ul>
                        {errorList.map(error => checkErrorText(error) ? <li className={style.errorText}>{error}</li> : '')}
                        </ul>
                    </div>
                </div>
            </div>
        : ''}
        </>
    );
}

export default Error;

import React, {useState} from "react";
import s from "./PatientDocTable.module.css";
import {Toaster} from "react-hot-toast";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";
import Plus from "../../../../assets/images/transparrent-plus.svg";
import {Empty, Table} from "antd";
import srcicon from "../../../../assets/images/srcicon.svg";
import filter from "../../../../assets/images/filter.svg";

const PatientsDocTable =  ({documentList, handleClick, docType}) => {

    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const columns =  [
        {
            title: "Date received",
            dataIndex: "created",
            key: "created",
        },
        {
            title: "Document name",
            dataIndex: "name",
            key: "name",
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Type",
            dataIndex: "documentType",
            key: "documentType",
        },
        {
            title: "Reviewed by",
            dataIndex: "reviewed_by",
            key: "reviewed_by",
        },
        {
            title: "Reviewed Date",
            dataIndex: "reviewed_date",
            key: "reviewed_date",
        },
        {
            title: "",
            dataIndex: "action",
            key: "action",
        },
    ];

    return (
        <div className={s.topWrapper}>
            {/* <Nav /> */}
            <Toaster position="top-center" reverseOrder={false} />
            <div  style={{width: '100%'}}>
                <div
                    style={{width: '100%'}}
                >
                    <div className={s.titleWrapper}>
                        <h3 className={s.title}>{docType}  {  ' Patient documents '} </h3>

                        {}
                    </div>
                    <div className="d-flex align-items-center allied-documents-wrap patientsheading" style={{float: 'right', marginBottom: '20px'}}>
                        <div style={{float: 'right'}}>
                            <CustomButton
                                title="Add document"
                                buttonHandle={handleClick}
                                icon={Plus}
                                buttonClassName={s.addButton}
                            />
                        </div>
                    </div>
                </div>



                <div className="custom-table-wrapper patients-table">
                    <Table
                        dataSource={documentList}
                        columns={columns}
                        locale={{
                            emptyText: isPageLoaded ? (
                                <Empty description="No Records Found"/>
                            ) : (
                                <p></p>
                            ),
                        }}
                    />
                </div>

            </div>
        </div>
    );

};

export default PatientsDocTable;

import { useEffect, useState } from "react";
import { ItemMenuButton } from "./ItemMenuButton";
import styles from "./VaccinesTable.module.css";
import Modal from "../../../../../components/helpers/Modal/Modal";
import { EditVaccineForm } from "../EditVaccineForm/EditVaccineForm";
import { VaccineDetails } from "../VaccineDetails/VaccineDetails";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { createAxiosInstance } from "../../../../../api/axiosConfig";
import toast from "react-hot-toast";

export const VaccinesTableItem = ({
  item,
  patientId,
  tenantVal,
  isMultiDose,
}) => {
  const queryClient = useQueryClient();
  const [isEditVaccineModalOpen, setIsEditVaccineModalOpen] = useState(false);
  const [isVaccineDetailsModalOpen, setIsVaccineDetailsModalOpen] =
    useState(false);

  useEffect(() => {
    if (isEditVaccineModalOpen) {
      setIsVaccineDetailsModalOpen(false);
    }
  }, [isEditVaccineModalOpen]);

  useEffect(() => {
    if (isVaccineDetailsModalOpen) {
      setIsEditVaccineModalOpen(false);
    }
  }, [isVaccineDetailsModalOpen]);

  const { mutate: deletePregnancy } = useMutation({
    mutationFn: async (id) => {
      const axiosInstance = createAxiosInstance();
      await axiosInstance.delete(`${tenantVal}/immunisation/delete`, {
        id: item.id,
      });
    },
    onSuccess: () => {
      toast("Vaccine has been deleted.");
      queryClient.invalidateQueries(["vaccines"]);
    },
    onError: () => {
      toast.error("Oups, something went wrong!");
    },
  });

  return (
    <li className={styles.tableItem}>
      <p aria-describedby="report-table-type-of-vaccine">
        {item.typeOfVaccine}
      </p>
      <p aria-describedby="report-table-date-given">{item.dateGiven}</p>
      <p aria-describedby="report-table-type">{item.type}</p>
      <p aria-describedby="report-table-batch-no">{item.batchNo}</p>
      <p aria-describedby="report-table-expiry-date">{item.expiryDate}</p>
      <ItemMenuButton
        downloadUrl={item.downloadLink}
        onDeleteClick={deletePregnancy}
        onDetailsClick={() => setIsVaccineDetailsModalOpen(true)}
        onEditClick={() => setIsEditVaccineModalOpen(true)}
      />
      {isVaccineDetailsModalOpen && (
        <Modal
          title="Vaccine details"
          position="center"
          onClose={() => setIsVaccineDetailsModalOpen(false)}
          hideCancel={true}
          width="800px"
          padding="24px 24px 0 24px"
        >
          <VaccineDetails
            onOpenEditClick={() => setIsEditVaccineModalOpen(true)}
            vaccine={item}
            closeModal={() => setIsVaccineDetailsModalOpen(false)}
            tenantVal={tenantVal}
          />
        </Modal>
      )}
      {isEditVaccineModalOpen && (
        <Modal
          title="Edit vaccine"
          position="center"
          onClose={() => setIsEditVaccineModalOpen(false)}
          hideCancel={true}
          width="800px"
        >
          <EditVaccineForm
            currentPatientId={patientId}
            closeModal={() => setIsVaccineDetailsModalOpen(false)}
            tenantVal={tenantVal}
            vaccine={item}
          />
        </Modal>
      )}
    </li>
  );
};

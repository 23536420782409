import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { styled } from "@mui/material/styles";
import moment from "moment";

// Custom styled DatePicker component
const StyledDatePicker = styled(DatePicker)(({ theme }) => ({
  "& .MuiOutlinedInput-root": {
    border: "none", // Remove the default border
    "& fieldset": {
      border: "1px solid var(--gray-400)", // Add your custom border on the fieldset
    },
    borderRadius: "12px",
    height: "48px",
    padding: "0 20px", // Adjust padding as necessary
    boxSizing: "border-box", // Ensure border-box sizing
    "&:hover fieldset": {
      border: "1px solid var(--gray-400)", // Add your custom border on hover
    },
    "&.Mui-focused fieldset": {
      border: "1px solid var(--gray-400)", // Add your custom border when focused
    },
  },
  "& .MuiInputBase-input": {
    height: "100%", // Ensure input field height is correct
    padding: "0", // Adjust padding as necessary
  },
}));

export default function CustomDatepicker({ value, onChange }) {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <DemoContainer components={["DatePicker"]} sx={{ paddingTop: 0 }}>
        <StyledDatePicker
          label=""
          inputFormat="DD/MM/YYYY"
          value={value ? moment(value).startOf("day") : null}
          onChange={(newValue) => {
            onChange(
              newValue ? moment(newValue).startOf("day").toDate() : null
            );
          }}
          sx={{ width: "100%" }}
        />
      </DemoContainer>
    </LocalizationProvider>
  );
}

import React from "react";

import s from "./ReuseForm.module.css";

const ReuseForm = ({ fields, onChange, onSubmit }) => {
  return (
    <form className={s.form} onSubmit={onSubmit}>
      {fields.map((field, index) => (
        <div key={index} className={s.inputWrapper}>
          <p className={s.rowTitle}>{field.label}:</p>
          <input
            type={field.type}
            name={field.name}
            className={s.input}
            placeholder={field.placeholder}
            value={field.value || ""}
            onChange={onChange}
          />
        </div>
      ))}
    </form>
  );
};

export default ReuseForm;

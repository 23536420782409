import React, { useState, useEffect } from "react";

import s from "./BaselineDetails.module.css";
import ModalBaselineDetails from "../ModalBaselineDetails/ModalBaselineDetails.jsx";
import {
  GET_PATIENT_BASELINE_BY_ID,
  POST_PATIENT_BASELINE,
} from "../../../../../api/Service.js";
import { useSelector } from "react-redux";
import CardWithFormModal from "../../../../../components/helpers/CardWithFormModal/CardWithFormModal.jsx";
import Pencil from "../../../../../assets/images/edit-green-pencil.svg";

const BaselineDetails = () => {
  // const [isModalOpen, setIsModalOpen] = useState(false);
  // const [baselineData, setBaselineData] = useState([]);
  // const [formState, setFormState] = useState({
  //   height: "",
  //   weight: "",
  //   waist: "",
  //   BP: "",
  // });

  // // MAIN VARIABLES
  // const tenantVal =
  //   useSelector((state) => state.auth.user?.tenant) ||
  //   localStorage.getItem("kinesin-tenant");

  // const patientId = localStorage.getItem("kinesin-patientId");

  // // FETCHING DATA
  // const fetchBaseline = async () => {
  //   try {
  //     const response = await getData(
  //       `${tenantVal}${GET_PATIENT_BASELINE_BY_ID}${patientId}`
  //     );
  //     console.log("Beseline response", response);
  //     setBaselineData(response);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   fetchBaseline();
  // }, []);

  // // HANDLERS
  // const handleOpenModal = () => {
  //   setIsModalOpen(true);
  // };
  // const handleCloseModal = () => {
  //   setIsModalOpen(false);
  // };
  // const handleInputChange = (event) => {
  //   const { name, value } = event.target;
  //   setFormState((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  // };

  // const handleSave = async (event) => {
  //   event.preventDefault();
  //   const formData = {
  //     patientId,
  //     heightCm: formState.height,
  //     weightKg: formState.weight,
  //     occupation: formState.occupation,
  //     alcoholUnitsPerWeek: formState.alcohol,
  //   };
  //   try {
  //     const response = await postData(
  //       `${tenantVal}${POST_PATIENT_BASELINE}`,
  //       formData
  //     );
  //     console.log("Server Social Response:", response);
  //     setIsModalOpen(false);
  //     fetchBaseline();
  //   } catch (error) {
  //     console.error("There was a problem with the axios operation:", error);
  //   }
  // };

  // const data = ["Height", "Weight", "Waist", "BP"];
  return (
    <>
      <CardWithFormModal
        title="Baseline Details"
        cardIcon={Pencil}
        minWidth={"295px"}
        apiEndpoints={{
          get: GET_PATIENT_BASELINE_BY_ID,
          post: POST_PATIENT_BASELINE,
        }}
        formFields={[
          {
            label: "Height",
            type: "number",
            name: "heightCm",
            placeholder: "enter height",
          },
          {
            label: "Weight",
            type: "number",
            name: "weightKg",
            placeholder: "enter weight",
          },

          {
            label: "Waist",
            type: "number",
            name: "waistCm",
            placeholder: "enter waist",
          },
          {
            label: "BP",
            type: "number",
            name: "bloodPressure",
            placeholder: "enter BP",
          },
        ]}
        localStorageKeys={{
          tenant: "kinesin-tenant",
          patientId: "kinesin-patientId",
        }}
      />
      {/* <CardWithTitle
        title={"Baseline Details"}
        width={"318px"}
        icon={PencilIcon}
        showButton={false}
        paddingBottom={"25px"}
        marginBottom={"25px"}
        onButtonClick={handleOpenModal}
      >
        <ul className={s.listContainer}>
          {data.map((item, index) => (
            <li key={index} className={s.listItemContainer}>
              <li className={s.listItemWrapper}>
                <img src={checkGreen} alt="check icon" />
                <p className={s.listItemTitle}>{item}</p>
              </li>
              <li>
                <p className={s.listItemDescr}>180 cm</p>
              </li>
              <li className={s.deleteIcon}>
                <button className={s.deleteButton}>
                  <img src={Trash} alt="trash icon" />
                </button>
              </li>
            </li>
          ))}
        </ul>
      </CardWithTitle>
      {isModalOpen && (
        <ModalBaselineDetails handleCloseModal={handleCloseModal} />
      )} */}
    </>
  );
};

export default BaselineDetails;

import { useEffect, useState } from "react";

export const useNavbarRwd = (menuItems) => {
  const [hiddenItems, setHiddenItems] = useState([]);
  const [visibleItems, setVisibleItems] = useState([]);

  useEffect(() => {
    const updateHiddenItems = () => {
      const screenWidth = window.innerWidth;

      if (screenWidth <= 1600 && screenWidth > 1200) {
        setHiddenItems(menuItems.slice(-3));
        setVisibleItems(menuItems.slice(0, -3));
      } else if (screenWidth <= 1200 && screenWidth > 700) {
        setHiddenItems(menuItems.slice(-6));
        setVisibleItems(menuItems.slice(0, -6));
      } else if (screenWidth <= 700 && screenWidth > 360) {
        setHiddenItems(menuItems.slice(-8));
        setVisibleItems(menuItems.slice(0, -8));
      } else {
        setHiddenItems([]);
        setVisibleItems(menuItems);
      }
    };

    updateHiddenItems();
    window.addEventListener("resize", updateHiddenItems);

    return () => {
      window.removeEventListener("resize", updateHiddenItems);
    };
  }, [menuItems]);

  return {
    hiddenItems,
    visibleItems,
  };
};

export const SVGIcons = {
  Documents: (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.9987 16.6668H4.9987C4.08203 16.6668 3.33203 15.9168 3.33203 15.0002V5.00016C3.33203 4.0835 4.08203 3.3335 4.9987 3.3335H8.33203C8.83203 3.3335 9.16536 3.66683 9.16536 4.16683C9.16536 4.66683 8.83203 5.00016 8.33203 5.00016H4.9987V15.0002H14.9987V11.6668C14.9987 11.1668 15.332 10.8335 15.832 10.8335C16.332 10.8335 16.6654 11.1668 16.6654 11.6668V15.0002C16.6654 15.9168 15.9154 16.6668 14.9987 16.6668Z"
        fill="currentColor"
      />
      <path
        d="M16.668 8.33333C16.168 8.33333 15.8346 8 15.8346 7.5V4.16667H12.5013C12.0013 4.16667 11.668 3.83333 11.668 3.33333C11.668 2.83333 12.0013 2.5 12.5013 2.5H16.668C17.168 2.5 17.5013 2.83333 17.5013 3.33333V7.5C17.5013 8 17.168 8.33333 16.668 8.33333Z"
        fill="currentColor"
      />
      <path
        d="M10.8333 10C10.5833 10 10.4167 9.91667 10.25 9.75C9.91667 9.41667 9.91667 8.91667 10.25 8.58333L16.0833 2.75C16.4167 2.41667 16.9167 2.41667 17.25 2.75C17.5833 3.08333 17.5833 3.58333 17.25 3.91667L11.4167 9.75C11.25 9.91667 11.0833 10 10.8333 10Z"
        fill="currentColor"
      />
    </svg>
  ),
  Details: (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8804 5.8766C10.7729 5.8766 10.6698 5.83388 10.5937 5.75784C10.5177 5.68181 10.475 5.57868 10.475 5.47114V3.24924C10.475 3.1417 10.4323 3.03857 10.3562 2.96254C10.2802 2.8865 10.1771 2.84378 10.0695 2.84378H8.63018C8.52265 2.84378 8.41952 2.80106 8.34349 2.72503C8.26745 2.64899 8.22473 2.54586 8.22473 2.43832C8.22473 2.33079 8.26745 2.22766 8.34349 2.15162C8.41952 2.07558 8.52265 2.03287 8.63018 2.03287H10.0695C10.3921 2.03287 10.7015 2.16102 10.9296 2.38913C11.1577 2.61725 11.2859 2.92664 11.2859 3.24924V5.47114C11.2859 5.57868 11.2432 5.68181 11.1671 5.75784C11.0911 5.83388 10.988 5.8766 10.8804 5.8766Z"
        fill="currentColor"
      />
      <path
        d="M7.27932 16H1.21647C0.893878 16 0.584494 15.8719 0.356383 15.6438C0.128273 15.4157 0.00012207 15.1063 0.00012207 14.7837V3.24924C0.00012207 2.92664 0.128273 2.61725 0.356383 2.38913C0.584494 2.16102 0.893878 2.03287 1.21647 2.03287H2.65583C2.76336 2.03287 2.86649 2.07558 2.94252 2.15162C3.01856 2.22766 3.06128 2.33079 3.06128 2.43832C3.06128 2.54586 3.01856 2.64899 2.94252 2.72503C2.86649 2.80106 2.76336 2.84378 2.65583 2.84378H1.21647C1.10894 2.84378 1.00581 2.8865 0.929778 2.96254C0.853741 3.03858 0.811024 3.1417 0.811024 3.24924V14.7837C0.811024 14.8912 0.853741 14.9943 0.929778 15.0704C1.00581 15.1464 1.10894 15.1891 1.21647 15.1891H7.27932C7.38685 15.1891 7.48998 15.2319 7.56601 15.3079C7.64205 15.3839 7.68477 15.4871 7.68477 15.5946C7.68477 15.7021 7.64205 15.8053 7.56601 15.8813C7.48998 15.9573 7.38685 16 7.27932 16Z"
        fill="currentColor"
      />
      <path
        d="M2.75873 7.23913C2.70546 7.23912 2.65271 7.22861 2.6035 7.2082C2.55429 7.1878 2.50959 7.15789 2.47194 7.1202L1.70294 6.35118C1.66528 6.31354 1.6354 6.26884 1.61501 6.21965C1.59462 6.17046 1.58412 6.11773 1.58411 6.06448C1.58409 6.01123 1.59457 5.9585 1.61494 5.9093C1.6353 5.8601 1.66516 5.81539 1.7028 5.77773C1.74045 5.74007 1.78514 5.71019 1.83433 5.6898C1.88352 5.66941 1.93625 5.65891 1.9895 5.6589C2.04275 5.65888 2.09548 5.66936 2.14468 5.68973C2.19388 5.71009 2.23858 5.73995 2.27625 5.77759L2.75873 6.26036L3.90805 5.10967C3.98415 5.0335 4.08739 4.99068 4.19505 4.99063C4.30272 4.99058 4.406 5.0333 4.48217 5.1094C4.55834 5.1855 4.60116 5.28874 4.60121 5.39641C4.60126 5.50408 4.55854 5.60736 4.48244 5.68353L3.04525 7.12047C2.96924 7.19643 2.86619 7.23911 2.75873 7.23913Z"
        fill="currentColor"
      />
      <path
        d="M2.75957 10.5972C2.70632 10.5972 2.65358 10.5867 2.60438 10.5664C2.55517 10.546 2.51045 10.5162 2.47279 10.4785L1.70378 9.70924C1.62992 9.63277 1.58906 9.53035 1.58998 9.42404C1.5909 9.31773 1.63355 9.21604 1.70872 9.14086C1.78389 9.06569 1.88559 9.02304 1.99189 9.02212C2.0982 9.0212 2.20062 9.06206 2.27709 9.13592L2.75957 9.61842L3.90889 8.46773C3.98536 8.39387 4.08778 8.353 4.19409 8.35393C4.3004 8.35485 4.40209 8.39749 4.47726 8.47267C4.55244 8.54784 4.59508 8.64954 4.596 8.75585C4.59692 8.86216 4.55606 8.96457 4.4822 9.04104L3.04501 10.4783C3.00761 10.5159 2.96314 10.5458 2.91415 10.5662C2.86517 10.5866 2.81264 10.5971 2.75957 10.5972Z"
        fill="currentColor"
      />
      <path
        d="M2.75908 13.9549C2.70583 13.9549 2.65309 13.9445 2.60388 13.9241C2.55467 13.9037 2.50996 13.8739 2.47229 13.8362L1.70329 13.0669C1.66222 13.0301 1.62909 12.9853 1.60594 12.9353C1.58279 12.8852 1.57009 12.8309 1.56862 12.7758C1.56716 12.7207 1.57696 12.6658 1.59742 12.6146C1.61789 12.5634 1.64859 12.5169 1.68764 12.4779C1.7267 12.439 1.77329 12.4084 1.82456 12.3881C1.87583 12.3678 1.93072 12.3581 1.98585 12.3598C2.04098 12.3614 2.09519 12.3742 2.14519 12.3975C2.19518 12.4208 2.23989 12.4541 2.27659 12.4953L2.75908 12.9777L3.9084 11.8262C3.98487 11.7524 4.08728 11.7115 4.19359 11.7124C4.2999 11.7134 4.40159 11.756 4.47677 11.8312C4.55194 11.9064 4.59458 12.0081 4.5955 12.1144C4.59643 12.2207 4.55556 12.3231 4.4817 12.3996L3.04452 13.8368C2.96869 13.9123 2.86608 13.9547 2.75908 13.9549Z"
        fill="currentColor"
      />
      <path
        d="M9.11913 5.80203H5.81768C5.71015 5.80203 5.60702 5.75932 5.53099 5.68328C5.45495 5.60724 5.41223 5.50411 5.41223 5.39658C5.41223 5.28904 5.45495 5.18591 5.53099 5.10987C5.60702 5.03384 5.71015 4.99112 5.81768 4.99112H9.11913C9.17238 4.99112 9.2251 5.00161 9.27429 5.02198C9.32348 5.04236 9.36818 5.07222 9.40583 5.10987C9.44348 5.14752 9.47335 5.19222 9.49372 5.24141C9.5141 5.29061 9.52459 5.34333 9.52459 5.39658C9.52459 5.44982 9.5141 5.50255 9.49372 5.55174C9.47335 5.60093 9.44348 5.64563 9.40583 5.68328C9.36818 5.72093 9.32348 5.75079 9.27429 5.77117C9.2251 5.79155 9.17238 5.80203 9.11913 5.80203Z"
        fill="currentColor"
      />
      <path
        d="M7.27866 7.48111H5.81768C5.71015 7.48111 5.60702 7.43839 5.53099 7.36235C5.45495 7.28632 5.41223 7.18319 5.41223 7.07565C5.41223 6.96812 5.45495 6.86499 5.53099 6.78895C5.60702 6.71291 5.71015 6.6702 5.81768 6.6702H7.27866C7.38619 6.6702 7.48932 6.71291 7.56535 6.78895C7.64139 6.86499 7.68411 6.96812 7.68411 7.07565C7.68411 7.18319 7.64139 7.28632 7.56535 7.36235C7.48932 7.43839 7.38619 7.48111 7.27866 7.48111Z"
        fill="currentColor"
      />
      <path
        d="M8.62922 3.75737H2.65557C2.54804 3.75737 2.44491 3.71465 2.36888 3.63861C2.29284 3.56257 2.25012 3.45944 2.25012 3.35191V1.52465C2.25012 1.41712 2.29284 1.31399 2.36888 1.23795C2.44491 1.16191 2.54804 1.11919 2.65557 1.11919H4.1725C4.26132 0.797496 4.45321 0.513817 4.71875 0.31166C4.98428 0.109503 5.3088 3.05176e-05 5.64253 3.05176e-05C5.97626 3.05176e-05 6.30077 0.109503 6.56631 0.31166C6.83184 0.513817 7.02374 0.797496 7.11256 1.11919H8.62949C8.73702 1.11919 8.84015 1.16191 8.91618 1.23795C8.99222 1.31399 9.03494 1.41712 9.03494 1.52465V3.35191C9.03494 3.40518 9.02444 3.45792 9.00405 3.50713C8.98366 3.55634 8.95377 3.60105 8.91609 3.63871C8.87841 3.67636 8.83368 3.70622 8.78446 3.72658C8.73524 3.74694 8.68248 3.7574 8.62922 3.75737ZM3.06102 2.94645H8.22377V1.93011H6.76144C6.65391 1.93011 6.55078 1.88739 6.47474 1.81135C6.39871 1.73531 6.35599 1.63218 6.35599 1.52465C6.35235 1.33776 6.27556 1.15975 6.1421 1.02887C6.00865 0.897983 5.82918 0.824666 5.64226 0.824666C5.45534 0.824666 5.27587 0.897983 5.14242 1.02887C5.00896 1.15975 4.93217 1.33776 4.92853 1.52465C4.92853 1.63218 4.88581 1.73531 4.80978 1.81135C4.73374 1.88739 4.63061 1.93011 4.52308 1.93011H3.06075L3.06102 2.94645Z"
        fill="currentColor"
      />
      <path
        d="M11.3349 16C8.76271 16 6.67004 13.9073 6.67004 11.3351C6.67004 8.76289 8.76271 6.6702 11.3349 6.6702C13.9071 6.6702 15.9997 8.76289 15.9997 11.3351C15.9997 13.9073 13.9071 16 11.3349 16ZM11.3349 7.48111C9.20979 7.48111 7.48095 9.21106 7.48095 11.3351C7.48095 13.4592 9.21087 15.1891 11.3349 15.1891C13.4589 15.1891 15.1888 13.4592 15.1888 11.3351C15.1888 9.21106 13.46 7.48111 11.3349 7.48111Z"
        fill="currentColor"
      />
      <path
        d="M12.1893 13.9925H10.4808C10.3732 13.9925 10.2701 13.9498 10.1941 13.8737C10.118 13.7977 10.0753 13.6946 10.0753 13.587V12.5947H9.08331C8.97578 12.5947 8.87265 12.552 8.79661 12.476C8.72057 12.4 8.67786 12.2968 8.67786 12.1893V10.481C8.67786 10.3734 8.72057 10.2703 8.79661 10.1943C8.87265 10.1182 8.97578 10.0755 9.08331 10.0755H10.0753V9.08322C10.0753 8.97569 10.118 8.87256 10.1941 8.79652C10.2701 8.72048 10.3732 8.67776 10.4808 8.67776H12.1893C12.2969 8.67776 12.4 8.72048 12.476 8.79652C12.5521 8.87256 12.5948 8.97569 12.5948 9.08322V10.0755H13.5871C13.6946 10.0755 13.7977 10.1182 13.8738 10.1943C13.9498 10.2703 13.9925 10.3734 13.9925 10.481V12.1893C13.9925 12.2968 13.9498 12.4 13.8738 12.476C13.7977 12.552 13.6946 12.5947 13.5871 12.5947H12.5948V13.587C12.5948 13.6946 12.5521 13.7977 12.476 13.8737C12.4 13.9498 12.2969 13.9925 12.1893 13.9925ZM10.8862 13.1816H11.7839V12.1893C11.7839 12.0818 11.8266 11.9786 11.9026 11.9026C11.9787 11.8266 12.0818 11.7838 12.1893 11.7838H13.1816V10.8864H12.1893C12.0818 10.8864 11.9787 10.8437 11.9026 10.7677C11.8266 10.6916 11.7839 10.5885 11.7839 10.481V9.48868H10.8862V10.481C10.8862 10.5342 10.8757 10.5869 10.8553 10.6361C10.835 10.6853 10.8051 10.73 10.7675 10.7677C10.7298 10.8053 10.6851 10.8352 10.6359 10.8556C10.5867 10.8759 10.534 10.8864 10.4808 10.8864H9.48876V11.7838H10.4808C10.534 11.7838 10.5867 11.7943 10.6359 11.8147C10.6851 11.8351 10.7298 11.8649 10.7675 11.9026C10.8051 11.9402 10.835 11.9849 10.8553 12.0341C10.8757 12.0833 10.8862 12.136 10.8862 12.1893V13.1816Z"
        fill="currentColor"
      />
    </svg>
  ),
  SliderLeftArrow: (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.48438 7.9668L1.00098 4.4834L4.48437 1"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        // fill="currentColor"
      />
    </svg>
  ),
  SliderRightArrow: (
    <svg
      width="6"
      height="9"
      viewBox="0 0 6 9"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 7.9668L4.4834 4.4834L1 1"
        stroke="currentColor"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        // fill="currentColor"
      />
    </svg>
  ),
  DownArrow: (
    <svg
      width="13"
      height="8"
      viewBox="0 0 13 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.22266L6.4834 6.70605L11.9668 1.22266"
        stroke="#060030"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  ),
};

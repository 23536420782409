import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";
import arrowUpIcon from "../../../../assets/images/arrowUp.svg";
import arrowDownIcon from "../../../../assets/images/arrowDown.svg";

import reportsStyles from "../Reports.module.css";
import { getQueryParamsFromObject } from "../utils/getQueryParamsFromObject";

export const PatientsFilters = ({ setQueryParams }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [filters, setFilters] = useState({
    patientFirstname: "",
    patientLastname: "",
    consuiltationStartDate: "",
    consultationEndDate: "",
    age: "",
    gmsStatus: "",
    gender: "",
  });

  return (
    <div>
      <div className={reportsStyles.inputGrid4}>
        <div>
          <label className={reportsStyles.formLabel}>Patient Frist Name</label>
          <input
            name="patientFirstname"
            className="form-control"
            value={filters.patientFirstname}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                patientFirstname: e.target.value || null,
              }));
            }}
          />
        </div>
        <div>
          <label className={reportsStyles.formLabel}>Patient Last Name</label>
          <input
            className="form-control"
            name="patientLastname"
            value={filters.patientLastname}
            onChange={(e) => {
              setFilters((prev) => ({
                ...prev,
                patientLastname: e.target.value || null,
              }));
            }}
          />
        </div>
        <div>
          <label className={reportsStyles.formLabel}>
            Consultation Start Date
          </label>
          <div className={reportsStyles.datePickerWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD-MM-YYYY"
                name="consuiltationStartDate"
                sx={{
                  width: "100%",
                  height: "36px",
                }}
                onChange={(newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    consuiltationStartDate:
                      moment(new Date(dayjs(newValue)))
                        .toISOString()
                        .split("T")[0] || null,
                  }));
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
        <div>
          <label className={reportsStyles.formLabel}>
            Consultation End Date
          </label>
          <div className={reportsStyles.datePickerWrapper}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="DD-MM-YYYY"
                name="consultationEndDate"
                sx={{
                  width: "100%",
                  height: "36px",
                }}
                onChange={(newValue) => {
                  setFilters((prev) => ({
                    ...prev,
                    consultationEndDate:
                      moment(new Date(dayjs(newValue)))
                        .toISOString()
                        .split("T")[0] || null,
                  }));
                }}
              />
            </LocalizationProvider>
          </div>
        </div>
      </div>
      {isExpanded && (
        <div className={reportsStyles.additionalFieldsWrapper}>
          <div className={reportsStyles.inputGrid3}>
            <div>
              <label className={reportsStyles.formLabel}>Age</label>
              <input
                className="form-control"
                type="text"
                inputMode="numeric"
                value={filters.age}
                onInput={(e) =>
                  (e.target.value = e.target.value.replace(/[^0-9]/g, ""))
                }
                name="age"
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    age: e.target.value || null,
                  }));
                }}
              />
            </div>
            <div>
              <label className={reportsStyles.formLabel}>GMS Status</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                value={filters.gmsStatus}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    gmsStatus: e.target.value || null,
                  }));
                }}
              >
                <option disabled selected value="">
                  Select
                </option>
                <option value="medical-card">Medical Card</option>
                <option value="doctor-visit-card">Doctor Visit Card</option>
                <option value="private">Private</option>
              </select>
            </div>
            <div>
              <label className={reportsStyles.formLabel}>Gender</label>
              <select
                as="select"
                className="form-select form-control custom-select"
                value={filters.gender}
                onChange={(e) => {
                  setFilters((prev) => ({
                    ...prev,
                    gender: e.target.value || null,
                  }));
                }}
              >
                <option disabled selected value="">
                  Select
                </option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
          </div>
        </div>
      )}
      {isExpanded ? (
        <button
          className={reportsStyles.expandButon}
          onClick={() => setIsExpanded(false)}
        >
          less filters <img src={arrowUpIcon} alt="show less" />
        </button>
      ) : (
        <button
          className={reportsStyles.expandButon}
          onClick={() => setIsExpanded(true)}
        >
          more filters <img src={arrowDownIcon} alt="show more" />
        </button>
      )}
      <button
        className={reportsStyles.applyFiltersButton}
        onClick={() => setQueryParams(getQueryParamsFromObject(filters))}
      >
        Apply filters
      </button>
    </div>
  );
};

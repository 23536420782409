import multiDoseVaccineIcon from "../../../../../assets/images/multiDoseVaccineIcon.svg";
import singleDoseVaccineIcon from "../../../../../assets/images/singleDoseVaccineIcon.svg";

import style from "../PatientImmunizations.module.css";

export const VaccineTypeSelectMenu = ({
  onSingleDoseSelect,
  onMultiDoseSelect,
  seletedVaccineType,
}) => {
  return (
    <aside className={style.vaccineTypeSelectMenu}>
      <ul>
        <li>
          <button
            className={seletedVaccineType === "single-dose" && style.selected}
            onClick={onSingleDoseSelect}
          >
            <img src={singleDoseVaccineIcon} alt="Single-dose Vaccines" />
            Single-dose Vaccines
          </button>
        </li>
        <li>
          <button
            className={seletedVaccineType === "multi-dose" && style.selected}
            onClick={onMultiDoseSelect}
          >
            <img src={multiDoseVaccineIcon} alt="Multi-dose Vaccines" />
            Multi-dose Vaccines
          </button>
        </li>
      </ul>
    </aside>
  );
};

import { PregnancyItem } from "./PregnancyItem";

import style from "./PregnancySidebar.module.css";

export const PregnancySidebar = ({
  setView,
  view,
  pregnancies,
  onAddPregnancyButtonClick,
}) => {
  if (pregnancies?.length < 1) {
    return null;
  }

  return (
    <aside className={style.pregnancySidebar}>
      <button
        className={style.addPregnancyButton}
        onClick={onAddPregnancyButtonClick}
        type="button"
      >
        Add new pregnancy
      </button>
      {pregnancies.map((pregnancy) => (
        <PregnancyItem
          pregnancy={pregnancy}
          setView={setView}
          key={pregnancy.id}
          view={view}
        />
      ))}
    </aside>
  );
};

import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useState } from "react";
import styles from "./VaccinesTable.module.css";

import menuIcon from "../../../../../assets/images/menu.svg";
import downloadIcon from "../../../../../assets/images/immunisation/download.svg";
import deleteIcon from "../../../../../assets/images/immunisation/delete.svg";
import editIcon from "../../../../../assets/images/immunisation/edit.svg";
import detailsIcon from "../../../../../assets/images/immunisation/details.svg";

export const ItemMenuButton = ({
  downloadUrl,
  onDeleteClick,
  onDetailsClick,
  onEditClick,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <button
        id="sortBy"
        aria-label="show menu"
        aria-controls={open ? "sortBy" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
        className={styles.menuButton}
      >
        <img src={menuIcon} alt="open menu" />
      </button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        className={styles.menuItemWrapper}
      >
        <MenuItem onClick={handleClose} className={styles.menuItem}>
          <button onClick={onDetailsClick}>
            <img src={detailsIcon} alt="see details" />
            See details
          </button>
        </MenuItem>
        <MenuItem onClick={handleClose} className={styles.menuItem}>
          <a href={downloadUrl} target="_blank" rel="noreferrer">
            <img src={downloadIcon} alt="dowload" />
            Download immunisation report
          </a>
        </MenuItem>
        <MenuItem onClick={handleClose} className={styles.menuItem}>
          <button onClick={onEditClick}>
            <img src={editIcon} alt="edit" />
            Edit
          </button>
        </MenuItem>
        <MenuItem onClick={handleClose} className={styles.menuItem}>
          <button onClick={onDeleteClick}>
            <img src={deleteIcon} alt="delete" />
            Delete
          </button>
        </MenuItem>
      </Menu>
    </div>
  );
};

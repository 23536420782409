import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import s from "./PatientPregancy.module.css";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import { NoPregnancySection } from "./NoPregnancySection/NoPregnancySection.jsx";
import PatientCard from "../PatientCard/PatientCard.jsx";
import Modal from "../../../../components/helpers/Modal/Modal.jsx";
import AddPregnancyForm from "./AddPregnancyForm/AddPregnancyForm.jsx";
import { PregnancySidebar } from "./PregnancySidebar/PregnancySidebar.jsx";
import { useQuery } from "@tanstack/react-query";
import { fetchPatientById } from "./utils/fetchPatientById.js";
import { useView, views } from "./hooks/useView.js";
import { PregnancyGeneralView } from "./PregnancyGeneralView/PregnancyGeneralView.jsx";
import { PregnancyAnteNatalView } from "./PregnancyAnteNatalView/PregnancyAnteNatalView.jsx";
import { PregnancyPostNatalView } from "./PregnancyPostNatalView/PregnancyPostNatalView.jsx";
import { fetchPregnanciesPatientById } from "./utils/fetchPregnanciesPatientById.js";
import { PregnancyLoader } from "./components/PregnancyLoader/PregnancyLoader.jsx";

const PatientPregnancy = () => {
  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const { isLoading, data: patient } = useQuery({
    queryKey: ["patient", patientId, tenantVal],
    queryFn: () => fetchPatientById(patientId, tenantVal),
  });

  const { isLoading: isPregnanciesLoading, data: pregnanciesData } = useQuery({
    queryKey: ["pregnancies", patientId, tenantVal],
    queryFn: () => fetchPregnanciesPatientById(patientId, tenantVal),
  });
  const pregnancies = pregnanciesData ?? [];

  const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
  const [view, setView] = useView(pregnancies);

  useEffect(() => {
    if (view.name === views.noPregnancies && pregnancies?.at(0)?.id) {
      setView({
        name: views.general,
        pregnancyId: pregnancies?.at(0)?.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPregnanciesLoading]);

  if (isPregnanciesLoading) {
    return <PregnancyLoader>Loading pregnancies...</PregnancyLoader>;
  }

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            {isLoading ? (
              <p>Patient documents is loading...</p>
            ) : (
              <>
                <PatientCard patient={patient} />
                <PregnancySidebar
                  setView={setView}
                  pregnancies={pregnancies}
                  onAddPregnancyButtonClick={() =>
                    setIsModalOpen({ open: true })
                  }
                  view={view}
                />
              </>
            )}
          </div>
          <div className={s.secondColumnWrapper}>
            <div className={s.secondColumnSubWrapper}>
              {view.name === views.noPregnancies && (
                <NoPregnancySection
                  onAddPregnancyButtonClick={() =>
                    setIsModalOpen({ open: true })
                  }
                />
              )}
              {view.name === views.general && (
                <PregnancyGeneralView
                  patientId={patientId}
                  tenantVal={tenantVal}
                  pregnancy={pregnancies.find(
                    ({ id }) => id === view.pregnancyId
                  )}
                />
              )}
              {view.name === views.anteNatal && (
                <PregnancyAnteNatalView
                  tenantVal={tenantVal}
                  patientId={patientId}
                  pregnancy={pregnancies.find(
                    ({ id }) => id === view.pregnancyId
                  )}
                />
              )}
              {view.name === views.postNatal && (
                <PregnancyPostNatalView
                  patientId={patientId}
                  tenantVal={tenantVal}
                  pregnancy={pregnancies.find(
                    ({ id }) => id === view.pregnancyId
                  )}
                />
              )}
            </div>
          </div>
        </div>
        {isModalOpen.open && (
          <Modal
            title="Add new pregnancy"
            position="center"
            onClose={() => setIsModalOpen({ open: false })}
            hideCancel={true}
            width="800px"
          >
            <AddPregnancyForm
              currentPatientId={patientId}
              closeModal={() => setIsModalOpen({ open: false })}
              tenantVal={tenantVal}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default PatientPregnancy;

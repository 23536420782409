import { createAxiosInstance } from "../../../../../api/axiosConfig.js";
import { PATIENT_CONTROLER_URL_ID } from "../../../../../api/Service.js";

export const fetchPatientById = async (id, tenantVal) => {
  if (!id) return;

  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(
    `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
  );

  return response.data;
};

import { useState } from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";

import reportsStyles from "../Reports.module.css";
import { getQueryParamsFromObject } from "../utils/getQueryParamsFromObject";

export const AppointmentsFilters = ({ setQueryParams }) => {
  const [filters, setFilters] = useState({
    dateRange: "",
  });

  return (
    <div className={reportsStyles.inputGrid3}>
      <div>
        <label className={reportsStyles.formLabel}>Service</label>
        <select as="select" className="form-select form-control custom-select">
          <option value="doctor-1">Service 1</option>
        </select>
      </div>
      <div>
        <label className={reportsStyles.formLabel}>Date range</label>
        <div className={reportsStyles.datePickerWrapper}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              name="dateRange"
              sx={{
                width: "100%",
                height: "36px",
              }}
              onChange={(newValue) => {
                setFilters((prev) => ({
                  ...prev,
                  dateRange:
                    moment(new Date(dayjs(newValue)))
                      .toISOString()
                      .split("T")[0] || null,
                }));
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div>
        <label className={reportsStyles.formLabel}>Appointment status</label>
        <select as="select" className="form-select form-control custom-select">
          <option value="doctor-1">Status 1</option>
        </select>
      </div>
      <button
        className={reportsStyles.applyFiltersButton}
        onClick={() => setQueryParams(getQueryParamsFromObject(filters))}
      >
        Apply filters
      </button>
    </div>
  );
};

import React from "react";
import style from "./EmptyState.module.css";
import emptyIcon from "../../../../assets/images/payments.svg";

const EmptyState = () => {
    return (
        <div className={style.emptyState}>
            <img className={style.element} alt="Element" src={emptyIcon} />
            <div className={style.text}>
                <div className={style.div}>
                    <div className={style.textWrapper}>No payments processed yet</div>
                    <p className={style.onceThePatient}>
                        Once the patient makes a first transaction,
                        the details will appear here.
                    </p>
                </div>

            </div>
        </div>
    );
};

export default EmptyState;


import { createAxiosInstance } from "../../../../../api/axiosConfig.js";

export const fetchAnteNatalConsultations = async (pregnancyId, tenantVal) => {
  if (!pregnancyId) return;

  const axiosInstance = createAxiosInstance();
  const response = await axiosInstance.get(
    `${tenantVal}/antenatalconsultation/getbypregnancyid/${pregnancyId}`
  );

  return response.data.map((item) => ({
    id: item.id,
    title: "Ante Natal consultation",
    date: new Date(item.consultationDate),
    doctor: `${item?.pregnancy.conductedByStaffMember?.firstName} ${item?.pregnancy.conductedByStaffMember?.lastName}`,
    bp: item.bloodPressure || "-",
    urinalysis: item.urinalysis || "-",
    ankleOedema: item.ankleOedema === "no" ? "No" : "Yes",
    foetalMovement: item.foetalMovelmentPalpation === "no" ? "No" : "Yes",
    symphysisFundalHeight: item.pregnancy.symphysis || "-",
    foetalHeartHeard: item.heartHeardWithDoppler === "no" ? "No" : "Yes",
    impression: item.impression || "-",
    plan: item.plan || "-",
    additionalInfo: item.additionalInfo || "-",
    attachments: {
      refferrals: [],
      prescriptions: [],
    },
  }));
};

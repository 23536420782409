import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import dayjs from "dayjs";

import reportsStyles from "../Reports.module.css";

export const MedicoLegalFilters = () => {
  return (
    <div className={reportsStyles.inputGrid4}>
      <div>
        <label className={reportsStyles.formLabel}>
          Appointment date range
        </label>
        <div className={reportsStyles.datePickerWrapper}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              name="dateRange"
              sx={{
                width: "100%",
                height: "36px",
              }}
              onChange={(newValue) => {
                console.log(
                  "dateRange",
                  moment(new Date(dayjs(newValue))).format("DD-MM-YYYY")
                );
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
      <div>
        <label className={reportsStyles.formLabel}>Doctor</label>
        <select as="select" className="form-select form-control custom-select">
          <option value="doctor-1">Doctor 1</option>
        </select>
      </div>
      <div>
        <label className={reportsStyles.formLabel}>Patient name</label>
        <input className="form-control" />
      </div>
      <div>
        <label className={reportsStyles.formLabel}>Patient DOB</label>
        <div className={reportsStyles.datePickerWrapper}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              format="DD-MM-YYYY"
              name="dateRange"
              sx={{
                width: "100%",
                height: "36px",
              }}
              onChange={(newValue) => {
                console.log(
                  "dateRange",
                  moment(new Date(dayjs(newValue))).format("DD-MM-YYYY")
                );
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

import { createAxiosInstance } from "../../../../../api/axiosConfig";

export const finishAnteNatalConsultation = async (
  values,
  tenantVal,
  pregnancyid,
  patientId,
  anteNatalConsultationId
) => {
  const axiosInstance = createAxiosInstance();

  return axiosInstance.post(`${tenantVal}/antenatalconsultation`, {
    tenant: tenantVal,
    patientId: patientId,
    pregnancyId: pregnancyid,
    bloodPressure: values.bloodPressure,
    urinalysis: values.urinalysis,
    ankleOedema: values.ankleOedema,
    foetalMovelmentPalpation: values.foetalMovelmentPalpation,
    symphysis: values.symphysis,
    heartHeardWithDoppler: values.heartHeardWithDoppler,
    impression: values.impression,
    plan: values.plan,
    additionalInfo: values.additionalInfo,
    id: anteNatalConsultationId || undefined,
  });
};

import React, { useState, useEffect } from "react";
import Table from "../../../../components/helpers/Table/Table.jsx";
import Modal from "../../../../components/helpers/Modal/Modal.jsx";
import Select from "../../../../components/helpers/Select/Select.jsx";
import "react-datepicker/dist/react-datepicker.css";

import { useSelector } from "react-redux";

import s from "./ConsultationEdit.module.css";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import CustomCheckbox from "../../../../components/helpers/CustomCheckbox/CustomCheckbox.jsx";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton.jsx";
import Plus from "../../../../assets/images/transparrent-plus.svg";
import CustomInput from "../../../../components/helpers/CustomInput/CustomInput.jsx";
import { useForm, FormProvider } from "react-hook-form";
import toast, { Toaster } from "react-hot-toast";
import Medications from "./Medications";
import style from "../PatientPregnancy/AddPregnancyForm/AddPregnancyForm.module.css";
import {FormField} from "../../../../components/form/FormField/FormField";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment/moment";
import dayjs from "dayjs";
import {EstimateddueDateField} from "../PatientPregnancy/AddPregnancyForm/EstimatedDueDateField";
import {zodResolver} from "@hookform/resolvers/zod";
import tooltipIcon from "../../../../assets/images/tooltip-icon.svg";
import {Tooltip} from "react-tooltip";

const ConsultationEdit = (patient) => {

    const today = dayjs(new Date().setMinutes(0));
    const form = useForm({
        defaultValues: {
            consultationType: "",
            consultationDate: moment(new Date(today)).format("DD-MM-YYYY"),
            doctorName: "",
        },
    });

    return (
        <div className={s.longTermWrapper}>
            <Toaster position="top-center" reverseOrder={false} />
            <div className={s.topWrapper}>
                <h3 className={s.title}>Consultation</h3>
            </div>
                <FormProvider {...form}>
                    <form
                        className={style.addPregnancyForm}
                    >
                        <div className="row">
                            <div className="col-md-4 mb-4">
                                <FormField name="consultDate">
                                    <div>
                                        <label className={style.formlabel}>
                                            Consultation type
                                        </label>
                                        <input
                                            name="estimated-due-date"
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value="Doctor consultation"
                                        />
                                    </div>
                                </FormField>
                            </div>
                            <div className="col-md-4 mb-4">
                                <FormField name="consultDate">
                                    <div>
                                        <label className={style.formlabel}>
                                            Consultation date
                                        </label>
                                        <input
                                            name="estimated-due-date"
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value="2024-11-07 15:22"
                                        />
                                    </div>
                                </FormField>

                            </div>
                            <div className="col-md-4 mb-4">
                                <FormField name="consultDate">
                                    <div>
                                        <label className={style.formlabel}>
                                            Consultation Doctor
                                        </label>
                                        <input
                                            name="estimated-due-date"
                                            disabled
                                            type="text"
                                            className="form-control"
                                            value="Darren McC"
                                        />
                                    </div>
                                </FormField>

                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-12 mb-12">
                                <label className={style.formlabel}>
                                    Consultation Note
                                </label>

                                <textarea
                                    placeholder="type notes here"
                                    className="form-control textareaform-control"
                                    value=""
                                    type="text"
                                    rows="15"
                                    maxLength="200"
                                />
                                <h5>Notes auto saves</h5>
                            </div>
                        </div>

                    </form>
                </FormProvider>
        </div>
    );


}

export default ConsultationEdit;

import React from "react";
import { BiChevronDown } from "react-icons/bi";
import s from "./Select.module.css";

const Select = ({ options, value, onChange }) => {
  const handleChange = (event) => {
    event.persist();
    onChange(event.target.value);
  };
  return (
    <div className={s.container}>
      <select className={s.select} value={value} onChange={handleChange}>
        {options.map((option) => (
          <option key={option} value={option}>
            {option}
          </option>
        ))}
      </select>
      <div className={s.icon}>
        <BiChevronDown />
      </div>
    </div>
  );
};

export default Select;

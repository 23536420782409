import React from "react";
import style from "./EmptyState.module.css";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";
import emptyDocs from "../../../../assets/images/family.svg";

const EmptyState = ({handleClick}) => {

    return (
        <div className={style.emptyState}>
            <img className={style.element} alt="Element" src={emptyDocs} />
            <div className={style.text}>
                <div className={style.div}>
                    <div className={style.textWrapper}>No family members assigned to the patient</div>
                    <p className={style.onceThePatient}>
                        You can add the patient to an existing family or create a new family and they will appear here.
                    </p>
                </div>

                <CustomButton
                    buttonClassName={style.uploadButton}
                    titleClassName={style.uploadButtonTitle}
                    title={"Add a family member"}
                    icon={false}
                    buttonHandle={handleClick}
                />
            </div>
        </div>
    );
};

export default EmptyState;


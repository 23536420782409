import React, { useState } from "react";
import NavigationBar from "../../PatientDetailGp/components/NavigationBar/NavigationBar.jsx";
import { useSelector } from "react-redux";
import PatientCard from "../PatientCard/PatientCard.jsx";

import s from "./PatientImmunizations.module.css";
import { useQuery } from "@tanstack/react-query";
import { fetchPatientById } from "../PatientPregnancy/utils/fetchPatientById.js";
import addIcon from "../../../../assets/images/plus.svg";
import Modal from "../../../../components/helpers/Modal/Modal.jsx";
import { AddVaccineForm } from "./AddVaccineForm/AddVaccineForm.jsx";
import { VaccinesTable } from "./VaccinesTable/VaccinesTable.jsx";
import { VaccineTypeSelectMenu } from "./VaccineTypeSelectMenu/VaccineTypeSelectMenu.jsx";

const PatientImmunizations = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [seletedVaccineType, setSeletedVaccineType] = useState("single-dose");
  const patientId = localStorage.getItem("kinesin-patientId");
  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const { isLoading, data: patient } = useQuery({
    queryKey: ["patient", patientId, tenantVal],
    queryFn: () => fetchPatientById(patientId, tenantVal),
  });

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            {isLoading ? (
              <p>Patient documents is loading...</p>
            ) : (
              <PatientCard patient={patient} />
            )}
            <VaccineTypeSelectMenu
              seletedVaccineType={seletedVaccineType}
              onSingleDoseSelect={() => setSeletedVaccineType("single-dose")}
              onMultiDoseSelect={() => setSeletedVaccineType("multi-dose")}
            />
          </div>
          <div className={s.secondColumnWrapper}>
            <div className={s.mainWrapper}>
              <div className={s.generalInfoTitleWrapper}>
                <h1 className={s.generalInfoTitle}>Vaccine History</h1>
                <button onClick={() => setIsModalOpen(true)}>
                  Add vaccine <img src={addIcon} alt="add pregnancy" />
                </button>
              </div>
              <VaccinesTable
                patientId={patientId}
                tenantVal={tenantVal}
                seletedVaccineType={seletedVaccineType}
              />
            </div>
          </div>
        </div>
        {isModalOpen && (
          <Modal
            title="Add new vaccine"
            position="center"
            onClose={() => setIsModalOpen(false)}
            hideCancel={true}
            width="800px"
          >
            <AddVaccineForm
              closeModal={() => setIsModalOpen(false)}
              tenantVal={tenantVal}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default PatientImmunizations;

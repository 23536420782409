/* eslint-disable jsx-a11y/anchor-is-valid */
import style from "./AddPregnancyForm.module.css";
import { Tooltip } from "react-tooltip";
import tooltipIcon from "../../../../../assets/images/tooltip-icon.svg";
import moment from "moment";

export const EstimateddueDateField = ({ form }) => {
  const lastMenstrualPregnancy = form.watch("lastMenstrualPeriod");
  const estimatedDueDate = moment(lastMenstrualPregnancy, "DD-MM-YYYY")
    .add(280, "days")
    .format("DD-MM-YYYY");

  return (
    <div>
      <label className={style.pregnancyFormLabel}>
        Estimated Due Date
        <a
          id="estimated-due-date-tooltip"
          className={style.addPregnancyFormTooltip}
        >
          <img src={tooltipIcon} alt="see more info about estimated due date" />
        </a>
        <Tooltip anchorSelect="#estimated-due-date-tooltip">
          EDD is calculated based on the date of the patient's Last Menstrual
          Period
        </Tooltip>
      </label>
      <input
        name="estimated-due-date"
        disabled
        type="text"
        className="form-control"
        value={estimatedDueDate}
      />
    </div>
  );
};

import React from "react";
import style from "./EmptyStateHorizontal.module.css";
import emptyIcon from "../../../../assets/images/history2.svg";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";

const EmptyState = () => {
    return (
        <div className={style.emptyStateWrapper}>
            <div className={style.topWrapper}>
                <div className={style.titleWrapper}>
                    <b className={style.title}>Medical History</b>
                </div>
            </div>
            <div className={style.emptyState}>
                <img className={style.icon} alt="" src={emptyIcon}/>
                <div className={style.text}>
                    <div className={style.text1}>
                        <b className={style.noMedicalHistory}>No medical history</b>
                        <div className={style.onceADoctorContainer}>
                            <p className={style.onceADoctor}>Once a doctor add medical history it will appear here.</p>
                            <p className={style.onceADoctor}></p>
                            <CustomButton
                                buttonClassName={style.uploadButton}
                                titleClassName={style.uploadButtonTitle}
                                title={"Add a medical history"}
                                icon={false}
                            />
                        </div>
                    </div>
                </div>
            </div>



        </div>
    );
};

export default EmptyState;


import React, { useEffect, useState } from "react";
import NavigationBar from "./components/NavigationBar/NavigationBar.jsx";
import { createAxiosInstance } from "../../../api/axiosConfig.js";
import { PATIENT_CONTROLER_URL_ID } from "../../../api/Service.js";
import { useSelector } from "react-redux";
import PatientDetailCard from "./components/PatientDetailCard/PatientDetailCard.jsx";

import s from "./PatientDeteilGp.module.css";
import AllergiesIntolerance from "./components/AllergiesIntolerance/AllergiesIntolerance.jsx";
import FamilyMedicalHistory from "./components/FamilyMedicalHistory/FamilyMedicalHistory.jsx";
import Social from "./components/Social/Social.jsx";
import Preferences from "./components/Preferences/Preferences.jsx";
import BaselineDetails from "./components/BaselineDetails/BaselineDetails.jsx";
import HealthIdentifiers from "./components/HealthIdentifiers/HealthIdentifiers.jsx";
import Consent from "./components/Consent/Consent.jsx";

const PatientDetailGp = () => {
  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  console.log("Patient info: ", patient);

  const patientId = localStorage.getItem("kinesin-patientId");

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");
  console.log(`${tenantVal}${PATIENT_CONTROLER_URL_ID}1`);

  const fetchPatientById = async (id) => {
    try {
      if (!id) return;
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
      );
      if (response && response.data) {
        setPatient(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (patientId) {
      fetchPatientById(patientId);
    }
  }, [patientId, tenantVal]);

  useEffect(() => {
    console.log("Оновлені дані пацієнта:", patient);
  }, [patient]);

  return (
    <>
      <NavigationBar />
      <div style={{ margin: "0 auto" }}>
        <div className={s.wrapper}>
          <div className={s.firstColumnWrapper}>
            {isLoading ? (
              <p>Patient data is loading...</p>
            ) : (
              <>
                <PatientDetailCard patient={patient} />
                <AllergiesIntolerance allergies={patient.allergies} />
              </>
            )}
          </div>
          <div className={s.secondColumnWrapper}>
            <FamilyMedicalHistory />
            <div className={s.secondColumnSubWrapper}>
              <Social />
              <Preferences />
            </div>
            <div className={s.thirdColumnWrapper}>
              <BaselineDetails />
              <HealthIdentifiers patient={patient} />
              <Consent />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientDetailGp;

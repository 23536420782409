import React, { forwardRef } from "react";
import s from "./CustomCheckbox.module.css";

const CustomCheckbox = forwardRef(
  ({ handleChange, checked, disabled, ...rest }, ref) => {
    return (
      <div className={s.checkboxWrapper}>
        <input
          className={s.checkbox}
          type="checkbox"
          checked={checked || undefined}
          onChange={handleChange}
          disabled={disabled}
          ref={ref}
          {...rest}
        />
        <span className={s.customCheckbox}></span>
      </div>
    );
  }
);

export default CustomCheckbox;

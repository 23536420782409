import React, {useState} from "react";
import s from "./PatientSms.module.css";
import {Toaster} from "react-hot-toast";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton";
import Plus from "../../../../assets/images/transparrent-plus.svg";
import {Empty, Table} from "antd";
import srcicon from "../../../../assets/images/srcicon.svg";
import filter from "../../../../assets/images/filter.svg";

const PatientsSmsTable =  ({smsList, handleClick, patient, handleSearch}) => {

    const [isPageLoaded, setIsPageLoaded] = useState(false);

    const columns =  [
        {
            title: "Date Sent",
            dataIndex: "created",
            key: "created",
        },
        {
            title: "Sent by",
            dataIndex: "sentByStaffMember",
            key: "sentByStaffMember",
        },
        {
            title: "Mobile #",
            dataIndex: "toNumber",
            key: "toNumber",
        },
        {
            title: "Status",
            dataIndex: "sentStatus",
            key: "sentStatus",
            render: (text) => {
                return text == 'QUEUED' ? 'SENT' : text;
            }
        },
        {
            title: "Message",
            dataIndex: "message",
            key: "message",
        },

    ];

    let searchQuery;

    const handleSearchChange = (event) => {
        const query = event.target.value;
        console.log(query + ' SEARCH QUERY ' );
        handleSearch(patient.id, query);
    }

    return (
        <div className={s.topWrapper}>
            {/* <Nav /> */}
            <Toaster position="top-center" reverseOrder={false} />
            <div  style={{width: '100%'}}>
                <div
                    style={{width: '100%'}}
                >
                    <div className={s.titleWrapper}>
                        <h3 className={s.title}>{  'Messages sent to '} {patient.firstName} {patient.surname} </h3>

                        {}
                    </div>
                    <div className="d-flex align-items-center allied-documents-wrap patientsheading" style={{float: 'right', marginBottom: '20px'}}>
                        <div className="search_bar">
                            <input
                                type="search"
                                className="form-control"
                                placeholder="Search messages..."
                                value={searchQuery}
                                onChange={handleSearchChange}
                            />
                            <img src={srcicon} alt="" />
                            <img
                                className="filterIcon"
                                src={filter}
                                alt=""
                            />
                        </div>
                        <div style={{float: 'right'}}>
                            <CustomButton
                                title="Send Sms"
                                buttonHandle={handleClick}
                                icon={Plus}
                                buttonClassName={s.addButton}
                            />
                        </div>
                    </div>
                </div>



                <div className="custom-table-wrapper patients-table">
                    <Table
                        dataSource={smsList}
                        columns={columns}
                        locale={{
                            emptyText: isPageLoaded ? (
                                <Empty description="No Records Found"/>
                            ) : (
                                <p></p>
                            ),
                        }}
                    />
                </div>

            </div>
        </div>
    );

};

export default PatientsSmsTable;

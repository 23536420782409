import reportStyles from "../Reports.module.css";

export const FiltersWrapper = ({ children, filtersCount }) => {
  return (
    <div className={reportStyles.filtersWrapper}>
      <h3>Filters {filtersCount > 0 && `(${filtersCount})`}</h3>
      <div>{children}</div>
    </div>
  );
};

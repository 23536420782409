import styles from "./PatientNewPrecriptionCard.module.css";
import {createAxiosInstance} from "../../../../api/axiosConfig";
import {PATIENT_CONTROLER_URL_ID} from "../../../../api/Service";
import { useNavigate } from "react-router-dom";
import Modal from "../../../../components/helpers/Modal/Modal";
import React, {useState} from "react";
import {Toaster} from "react-hot-toast";
import Error from "../../../../components/Error/Error";
import {Autocomplete, TextField} from "@mui/material";
import {TaskPriority, Taskstatus} from "../../../../Utils/Constant";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DemoContainer} from "@mui/x-date-pickers/internals/demo";
import dayjs from "dayjs";
import moment from "moment";

import s from "../../../../view/pages/PatientDetailGp/components/EditModalForm/EditModalForm.module.css";

const PatientNewPrecriptionCard = (patient) => {

    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });

    const handleCloseModal = () => {
        setIsModalOpen({ open: false, id: null });
        //clearForm();
    };

    const handleSave = async (event) => {

    };

    const newPrescriptionClick =  () => {
        setIsModalOpen({ open: true });

    };



    return (
        <div className={styles.nnumbersParent}>
            <div className={styles.nnumbers}>
                <div className={styles.march2023Parent}>
                     <b className={styles.b}>Prescriptions</b>
                    <b className={styles.b}></b>
                </div>
            </div>
            <div className={styles.button}>
                <div className={styles.bookAnAppointment} onClick={newPrescriptionClick}>Add prescription</div>
            </div>
            {isModalOpen.open && (
                <Modal
                    width="800px"
                    title="Add new prescription"
                    onClose={handleCloseModal}
                    onSave={handleSave}
                    position="center"
                >
                    <form onSubmit={handleSave}>

                        <div>
                            <Toaster position="top-center" reverseOrder={false}/>
                            <div className="dashBcontbody addpatentdashbord">
                                <div className="signup_threebox addpatientlist mt-0">

                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label className={s.label}>Drug name</label>
                                                <input
                                                    type="text"
                                                    placeholder="Search for drug"
                                                    className="form-control"
                                                    value=""

                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className={s.label}>Dosage</label>
                                                <input
                                                    type="text"
                                                    placeholder="Select dosage"
                                                    className="form-control"
                                                    value=""

                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label className={s.label}>Form</label>
                                                <input
                                                    type="text"
                                                    placeholder="Search for drug"
                                                    className="form-control"
                                                    value=""

                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className={s.label}>Start date</label>
                                                <input
                                                    type="text"
                                                    placeholder="Select start date"
                                                    className="form-control"
                                                    value=""
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label className={s.label}>End date</label>
                                                <input
                                                    type="text"
                                                    placeholder="Select end date"
                                                    className="form-control"
                                                    value=""

                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className={s.label}>Duration</label>
                                                <input
                                                    type="text"
                                                    placeholder="Select duration"
                                                    className="form-control"
                                                    value=""
                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6 mb-3">
                                                <label className={s.label}>Usage type</label>
                                                <input
                                                    type="text"
                                                    placeholder="Select usage type"
                                                    className="form-control"
                                                    value=""
                                                />
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <label className={s.label}>Repeat</label>
                                                <input
                                                    type="text"
                                                    placeholder="Select repeat"
                                                    className="form-control"
                                                    value=""

                                                />
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-12 mb-12">
                                                <label className={s.label}>Notes</label>
                                                <textarea
                                                    rows="5"
                                                    placeholder="Enter notes"
                                                    className="form-control"
                                                    value=""

                                                />
                                            </div>

                                        </div>

                                    </div>

                                    <div className="modal-footer">

                                    </div>
                                </div>
                            </div>
                        </div>


                    </form>
                </Modal>
            )}
        </div>


    );
}

export default PatientNewPrecriptionCard;
import React, { useState, useEffect } from "react";
import Table from "../../../../components/helpers/Table/Table.jsx";
import Modal from "../../../../components/helpers/Modal/Modal.jsx";
import Select from "../../../../components/helpers/Select/Select.jsx";
import "react-datepicker/dist/react-datepicker.css";

import { useSelector } from "react-redux";

import s from "./Medications.module.css";
import { createAxiosInstance } from "../../../../api/axiosConfig.js";
import CustomCheckbox from "../../../../components/helpers/CustomCheckbox/CustomCheckbox.jsx";
import CustomButton from "../../../../components/helpers/CustomButton/CustomButton.jsx";
import Plus from "../../../../assets/images/transparrent-plus.svg";
import CustomInput from "../../../../components/helpers/CustomInput/CustomInput.jsx";
import toast, { Toaster } from "react-hot-toast";

const Medications = (patient) => {
    const [isModalOpen, setIsModalOpen] = useState({ open: false, id: null });
    const [data, setData] = useState([]);

    const tenantVal =
        useSelector((state) => state.auth.user?.tenant) ||
        localStorage.getItem("kinesin-tenant");

    const patientId = localStorage.getItem("kinesin-patientId");

    const userName =
        useSelector((state) => state.auth.user?.name) ||
        localStorage.getItem("kinesin-name");

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        try {
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.get(`${tenantVal}/patientmedication/getbypatientid/${patientId}`);

            const dataWithUser = response.data.map((item) => ({
                ...item,
                entry: userName,
            }));

        } catch (error) {
            //toast.error(`Error fetching data: ${error.message}`);
            console.error("There was a problem with the axios operation:", error);
        }
        setData([
            {
                name: "Atorvastatin",
                dose: "10mg",
                startDate: "2024-10-02",
                prescriptionType: "LONGTERM"
            },
            {
                name: "Amoxicillin",
                dose: "500mg",
                startDate: "2024-10-17",
                prescriptionType: "ACUTE"

            }
        ]);
    };

    const handleOpenModal = (id) => {
        console.log("id : ", id);
        setIsModalOpen({ open: true, id: typeof id === "number" ? id : null });
        if (id) {
            const selectedData = data.find((item) => item.id === id);
            if (selectedData) {
               // setName(selectedData.name);
             //   setAllergyReaction(selectedData.allergyReaction);
              //  setSerious(selectedData.serious);
            }
        }
    };
    const handleCloseModal = () => {
        setIsModalOpen({ open: false, id: null });
        clearForm();
    };

    const handleSave = async (event) => {

        event.preventDefault();
        const formData = {
            id: isModalOpen.id ?? null,
            patientId: patientId
        };

        try {
            const axiosInstance = createAxiosInstance();
            const response = await axiosInstance.post(
                `${tenantVal}/patientmedication`,
                formData
            );
            toast.success("Data saved successfully!");
            setIsModalOpen({ open: false, id: null });
            clearForm();
            fetchData();
        } catch (error) {
            toast.error(`Error saving data: ${error.message}`);
            console.error("There was a problem with the axios operation:", error);
        }
    };

    const clearForm = () => {

    };

    const columns = [
        { header: "Drug Name", field: "name" },
        { header: "Dose", field: "dose" },
        { header: "Date prescribed", field: "startDate" },
        { header: "Type", field: "prescriptionType" }
    ];

    return (
        <div className={s.longTermWrapper}>
            <Toaster position="top-center" reverseOrder={false} />
            <div className={s.topWrapper}>
                <h3 className={s.title}>Patient medications</h3>
                <CustomButton
                    title="Add new"
                    buttonHandle={handleOpenModal}
                    icon={Plus}
                    buttonClassName={s.addBtn}
                />
            </div>
            <Table
                columns={columns}
                data={data}
                tableHeader={true}
                onEdit={handleOpenModal}
                onSave={handleSave}
            />

            {isModalOpen.open && (
                <Modal
                    width="560px"
                    title="Allergies"
                    onClose={handleCloseModal}
                    onSave={handleSave}
                    position="center"
                >
                    <form onSubmit={handleSave}>

                    </form>
                </Modal>
            )}
        </div>
    );
};

export default Medications;

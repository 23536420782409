import React, { useState, useEffect } from "react";
import styles from "../PatientDetailCard/PatientDetailCard.module.css";
import CustomButton from "../../../../../components/helpers/CustomButton/CustomButton.jsx";
import pencil from "../../../../../assets/images/edit-green-pencil.svg";
import Modal from "../../../../../components/helpers/Modal/Modal.jsx";
import EditModalForm from "../EditModalForm/EditModalForm.jsx";
import { createAxiosInstance } from "../../../../../api/axiosConfig.js";
import { useSelector } from "react-redux";
import {
  PATIENT_CONTROLER_URL,
  PATIENT_CONTROLER_URL_ID, TASK_CONTROLER_URL,
} from "../../../../../api/Service.js";
import { postData } from "../../../../../components/API/POST.js";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";
import SmsModal2 from "../../../../../components/Modal/SmsModal2";
import TaskModal2 from "../../../../../components/Modal/TaskModal2";
import {logoutFromHelper} from "../../../../../api/Helper";
import {logout} from "../../../../../redux/auth/AuthSlice";
import {removeuser} from "../../../../../redux/user/userSlice";
import s from "../../../PatientGp/PatientFamily/PatientFamily.module.css";
import Merge from "./PatientMerge";

const calculateAge = (birthDate) => {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDifference = today.getMonth() - birth.getMonth();
  if (
    monthDifference < 0 ||
    (monthDifference === 0 && today.getDate() < birth.getDate())
  ) {
    age--;
  }
  return age;
};

const PatientDetailCard = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let [isSmsModalOpen, setIsSmsModalOpen] = useState(false);
  const [showTaskModal, setShowTaskModal] = useState(false); // State for modal visibility
  let [isMergeModalOpen, setIsMergeModalOpen] = useState(false);

  const [patient, setPatient] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  // console.log(
  //   "patient.address.addressLine1",
  //   patient.address.addressLine1
  // );

  useEffect(() => {
    console.log("Patient Data", patient);
  }, [patient]);

  const patientId = localStorage.getItem("kinesin-patientId");

  const tenantVal =
    useSelector((state) => state.auth.user?.tenant) ||
    localStorage.getItem("kinesin-tenant");

  const fetchPatientById = async (id) => {
    try {
      if (!id) return;
      setIsLoading(true);
      const axiosInstance = createAxiosInstance();
      const response = await axiosInstance.get(
        `${tenantVal}${PATIENT_CONTROLER_URL_ID}${id}`
      );
      if (response && response.data) {
        console.log(response.data)
        setPatient(response.data);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(`Error fetching data: ${error.message}`);
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (patientId) {
      fetchPatientById(patientId);
    }
  }, [patientId, tenantVal]);

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const handleInputAddressChange = (field, value) => {
    setPatient((prevpatient) => ({
      ...prevpatient,
      address: {
        ...prevpatient.address,
        [field]: value,
      },
    }));
  };

  const handleDoctorChange = (e) => {
    console.log('handleDoctorChange ');
    console.log(e);
    const { name, value } = e.target;
    console.log(name + ' ' + value);

    setPatient((prevpatient) => ({
      ...prevpatient,
      ['doctorDetailsId']: value,
    }));

    //setFormData({ ...formData, [name]: value });
  };

  const handleInputChange = (field, value) => {
    console.log('change '  + field + ' '  + value);
    setPatient((prevpatient) => ({
      ...prevpatient,
      [field]: value,
    }));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  const getInitials = (name) => {
    if (!name) return "";
    const names = name.split(" ");
    const initials = names.map((n) => n[0].toUpperCase()).join("");
    return initials;
  };

  const age = calculateAge(patient.dateOfBirth);

  const { city, addressLine1, addressLine2, postCode } = patient.address;

  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };


  const toggleSmsModal = () => {
    console.log("Send sms")
    isSmsModalOpen = !isSmsModalOpen;
    setIsSmsModalOpen(isSmsModalOpen);
  };

  const toggleMergeModal = () => {
    console.log('merging patients')
    setIsDropdownOpen(false)
    isMergeModalOpen = !isMergeModalOpen;
    setIsMergeModalOpen(isMergeModalOpen);
  };

  const toggleTaskModal = () => {
    console.log("Send toggleTaskModal");
    setShowTaskModal(!showTaskModal);
    console.log("Send toggleTaskModal2");

  };

  const handleModalSave = async (e) => {
    e.preventDefault();
    try {
      const response = await postData(`${tenantVal}${PATIENT_CONTROLER_URL}`, {
        ...patient,
        dateOfBirth: patient.dateOfBirth
          ? moment(patient.dateOfBirth).format("YYYY-MM-DD")
          : null,
      });
      toast.success("Data saved successfully!");
      setIsModalOpen(false);
      fetchPatientById(patientId);
    } catch (error) {
      console.error("There was a problem with the axios operation:", error);
    }
  };

  const handleSaveTask =  async (newTask) => {
    console.log("SAVING TASK $$$$$$$")
    console.log(newTask)
    try {
      const axiosInstance = createAxiosInstance();
      let response;
        response = await axiosInstance.post(
            `${tenantVal}${TASK_CONTROLER_URL}`,
            newTask,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        );
        toast.success("New Task created successfully");
    } catch (error) {
      errorHandling(error);
    }
      setShowTaskModal(false);
  };

  const createNewTask = async (userData) => {
    try {
      const axiosInstance = createAxiosInstance();
      let response;
        response = await axiosInstance.post(
            `${tenantVal}${TASK_CONTROLER_URL}`,
            userData,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        );
        toast.success("New Task created successfully");
    } catch (error) {
      errorHandling(error);
    }
  };

  const errorHandling = (error) => {
    if (error && error.response) {
      if (error.response.data && error.response.data.message)
        toast.error(error.response.data.message);
    }
  };


  return (
    <div
      className={`${styles.patientDetailCard} bg`}
    >

      {showTaskModal && (
          <Modal
          title={"Add Task"}
          width="850px"
          onClose={toggleTaskModal}
          onSave={handleSaveTask}
      >
      <TaskModal2
          headerTitle="Add Task"
          showModal={showTaskModal}
          setShowModal={setShowTaskModal}
          createNewTask={createNewTask}
          patientObj={patient}
      />
      </Modal>
      )}

      {isModalOpen && (
        <Modal
          title={"Edit profile"}
          width="1350px"
          height="90%"
          onClose={toggleModal}
          onSave={handleModalSave}
        >
          <EditModalForm
            patient={patient}
            handleInputChange={handleInputChange}
            handleInputAddressChange={handleInputAddressChange}
            handleInputDoctorChange={handleDoctorChange}
          />
        </Modal>
      )}

      {isMergeModalOpen && (
          <Modal
              title="Merge patients"
              position="center"
              onClose={toggleMergeModal}
              hideCancel={true}
              height="850px"
          >
            <div className={s.inputWrapper}>
              <Merge currentPatientId={patientId} closeModal={toggleMergeModal}></Merge>
            </div>
          </Modal>
      )}


      {/* Modal */}
      {isSmsModalOpen  &&
          (
              <Modal
                  onClose={toggleSmsModal}
                  title={"Send sms"}
                  hideCancel={true}
                   >
                {/* Modal */}
                <SmsModal2
                    tenantVal={tenantVal}
                    patient={patient}
                    onClose={toggleSmsModal}
                />
                {/* Modal */}
              </Modal>)}
      {/* Modal */}


      <div className={styles.patientHeaderWrap}>
        <div className="d-flex align-items-center">
          <div className={`${styles.patientProfileImgWrap} flex-shrink-0`}>
            {patient.avatarUrl ? (
                <img
                    src={patient.avatarUrl}
                    alt="Patient Avatar"
                    className={styles.userProfileImage}
                />
            ) : (
                <span className={styles.userProfileImage}>
                {getInitials(`${patient.firstName} ${patient.surname}`)}
              </span>
            )}
          </div>
          <div className={styles.patientHeaderContentWrap}>
            <div className={styles.patientName}>
              <p
                  title={patient.firstName}
                  className={`text-ellipsis ${styles.patientNameSurname}`}
                  style={{fontSize: "23px"}}
              >
                {patient.firstName} {patient.surname} ({age})
              </p>
            </div>
            <p style={{width: '100%'}}>DOB : {moment(patient.dateOfBirth).format("DD-MM-YYYY")} <span
                style={{color: '#ef0064', paddingLeft: '20px'}}> {patient.died ? 'DECEASED' : ''}</span></p>
          </div>
          <div className="d-flex flex-sm-column ms-sm-auto mt-md-0 align-self-start modal-btn-wrap gap-3">
            <CustomButton
                buttonClassName={styles.editBtn}
                title={"Edit"}
                icon={pencil}
                buttonHandle={toggleModal}
            />

          </div>

          <div className="d-flex flex-sm-column ms-sm-auto mt-md-0 align-self-start modal-btn-wrap gap-3">
            <button className={styles.moreButton} onClick={toggleDropdown}>
              ⋮
            </button>
            {isDropdownOpen && (<div className={styles.dropdownMenu}>
              <a className={styles.dropdownItem} onClick={toggleMergeModal}>
                <span>Merge patient</span>
              </a>
            </div>
                )}
          </div>

        </div>
        <div className={styles.actionBtnsWrapper}>
          <CustomButton
              buttonClassName={styles.actionBtn}
              buttonHandle={toggleSmsModal}
              title={"Send SMS"}
              icon={false}
          />
          <CustomButton
              buttonClassName={styles.actionBtn}
              buttonHandle={toggleTaskModal}
              title={"Add task"}
              icon={false}
          />
        </div>
      </div>
      <div className={`${styles.patientBottomWrap} d-flex flex-wrap`}>
        <div className={styles.patientLeftDescription}>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} d-inline-block`}>
              Phone no:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} mb-0`}>
              {patient.mobilePhone ? patient.mobilePhone : patient.homePhone}
            </h6>
          </div>

          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Address:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0 `}>
              {addressLine2 ? addressLine2 : addressLine1},{" "}
              {city && `${city} ${postCode}`}
            </h6>
          </div>

          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Public:
            </span>
            <h6
                className={`${styles.h6} ${styles.textStratos} fw-500 mb-0 ${styles.textEllipsis}`}
            >
              Medical card no. {patient.medicalCardNumber}
            </h6>
          </div>

          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Next of kin
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              {patient.nextOfKin ? patient.nextOfKin + ' (' + patient.nextOfKinRelationship + ")" : 'Not set'}
            </h6>
          </div>

          {patient.died && <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Date of death
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              {patient.dateOfDeath ? moment(patient.dateOfDeath).format("DD-MM-YYYY")  : 'Not set'}
            </h6>
          </div>}

        </div>
        <div className={styles.divider}></div>
        <div className={styles.patientRightDescription}>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Opportunities:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              -
            </h6>
          </div>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Upcoming appointments:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              {patient.nextApppointment
                  ? moment(patient.nextApppointment).format("DD MMMM YYYY")
                  : "N/A"}
            </h6>
          </div>
          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Registered doctor:
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              {patient.doctorDetails ? patient.doctorDetails.firstName + ' ' + patient.doctorDetails.lastName : 'Not set'}
            </h6>
          </div>

          <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Next of kin phone
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              {patient.nextOfKinPhone ? patient.nextOfKinPhone : 'Not set'}
            </h6>
          </div>

          {patient.died && <div className={styles.patientBlockWrap}>
            <span className={`${styles.textManatee} fw-500 d-inline-block`}>
              Cause of death
            </span>
            <h6 className={`${styles.h6} ${styles.textStratos} fw-500 mb-0`}>
              {patient.causeOfDeath ? patient.causeOfDeath : 'Not set'}
            </h6>
          </div>}
        </div>
      </div>


    </div>

  );
};

export default PatientDetailCard;
